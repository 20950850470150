import { Component } from '@angular/core';

@Component
({
  selector: 'main-auth',
  template: '<router-outlet></router-outlet>',
  styles: [ ':host { display: block; max-width: 1200px; margin: 0 auto; }' ]
})
export class AuthCom {
  isLoggedIn = false;
  showContactSubnavigation = false;
  showAboutSubnavigation = false;
  showNavigation = false;
  logout() {
    console.log('dummy logout');
  }
  openModalCallback() {
    console.log('dummy modal');
  }
}
