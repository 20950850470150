import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BriefStateMdl } from './brief-mdl';
import { BriefStateFty } from './brief-state.fty';

@Injectable()
export abstract class ABriefSrv
{
  constructor( protected readonly briefStateFty: BriefStateFty ) { }
  protected toInternal( b: any )
  {
    return Object.entries(b || {})
      .reduce( ( acc, [key, val] ) => (acc[ key.replace(/[-_\s]([a-z])/g, ( _, v ) => v.toUpperCase() ) ] = val, acc) , {} );
  }
  protected toExternal( b: any )
  {
    return Object.entries(b || {})
      .reduce( (acc, [key, val] ) => (acc[ key.replace(/\.?([A-Z])/g, ( v: string) => `_${v.toLowerCase()}`) ] = val, acc ), {} );
  }
  abstract list(): Observable<BriefStateMdl[]>;
  abstract get( id: number ): Observable<BriefStateMdl>;
}
