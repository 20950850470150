import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { environment } from 'src/environments/environment';

export function isProd(): boolean { return environment.production; }

const routes: Routes =
[
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule
({
  imports:
  [
    RouterModule.forRoot( routes,
    {
      onSameUrlNavigation: 'reload',
      enableTracing: !isProd(),
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingMod { }
