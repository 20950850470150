import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { Validators } from '../../+';
import { BriefStep5Type, BriefStep5Fields } from '../brief-mdl';
import { ABriefFormStep } from './a.com';

const { atLeastOneChecked } = Validators;
const notEmpty = ({ id, value }) => !!`${id}${value}`.trim();
@Component
  ({
    selector: 'brief-form-step5',
    templateUrl: './step5.com.pug',
    styleUrls: ['./+.scss', './step5.com.scss'],
  })
export class BriefFormStep5Com extends ABriefFormStep<BriefStep5Type> implements OnInit
{
  personalRequirementsList: any[];
  specialRequirementsList: any[];
  languagesList: any[];
  form: FormGroup;
  fieldKeys = BriefStep5Fields;
  ngOnInit()
  {
    const [fields, fieldKeys] = [this.fields, this.fieldKeys];
    this.form = new FormGroup
    ({
      [fieldKeys.personalRequirements]: (fields[fieldKeys.personalRequirements] = new FormArray([], atLeastOneChecked)),
      [fieldKeys.specialSituations]: (fields[fieldKeys.specialSituations] = new FormArray([], atLeastOneChecked)),
      [fieldKeys.languages]: (fields[fieldKeys.languages] = new FormArray([], Validators.atLeastOneChecked)),
    });
  }
  protected fillDataCallback( data: any )
  {
    zip
    (
      this.apiSrv.personalRequirements,
      this.apiSrv.specialRequirements,
      this.apiSrv.languages,
    ).pipe
    (
      tap(([personal, special, langs]) =>
      {
        const
          list = [this.personalRequirementsList, this.specialRequirementsList, this.languagesList]
            = [personal.filter(notEmpty), special.filter(notEmpty), langs.filter(notEmpty)],
          storeList = [ data.personalRequirements || [] , data.specialSituations || [], data.languages || [] ];
        [ this.fields.personalRequirements, this.fields.specialSituations, this.fields.languages]
        .forEach((formArray: FormArray, index: number) =>
        {
          const currentList = list[index];
          if (formArray.controls.length < list[index].length)
            Array(currentList.length - formArray.controls.length).fill(undefined)
              .forEach( (_, i) => formArray.push(new FormControl( ( storeList[index] && storeList[index][i] ) || false)));
          this.form.markAsPristine();
        });
      })
    ).subscribe();
  }
}
