import { Component, ViewChild, TemplateRef, HostBinding } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, takeWhile, finalize } from 'rxjs/operators';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BriefStatuses, BriefApiSrv, BriefClientSrv } from './brief';
import { FORM_PATH } from './brief/routing';

@Component
({
  selector: 'dashboard-new',
  templateUrl: './dashboard-new.com.pug',
  styleUrls: ['./dashboard-new.com.scss'],
})
export class DashboardNewCom
{
  @HostBinding('class.x-small') private xSmallClass = false;
  @HostBinding('class.small') private smallClass = false;
  @HostBinding('class.medium') private mediumClass = false;
  @HostBinding('class.large') private largeClass = false;
  @HostBinding('class.x-large') private xLargeClass = false;

  projects$: Observable<any[]> = of([]);
  activeProject: number;
  loading: boolean;
  statuses = Object.keys(BriefStatuses);
  statusValues = Object.values(BriefStatuses);
  constructor
  (
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly clientSrv: BriefClientSrv,
    private readonly apiSrv: BriefApiSrv,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    readonly brkPntObs: BreakpointObserver,
  )
  {
    this.projects$ = this.route.data.pipe( map( ({ projects }) => projects ) );

    Breakpoints.XSmall = '(max-width: 304px)';
    Breakpoints.Small = '(min-width: 305px) and (max-width: 768px)';
    Breakpoints.Medium = '(min-width: 769px) and (max-width: 992px)';
    Breakpoints.Large = '(min-width: 993px) and (max-width: 1200px)';
    Breakpoints.XLarge = '(min-width: 1201px)';
    brkPntObs.observe( Breakpoints.XSmall ).subscribe( r => this.xSmallClass = r.matches );
    brkPntObs.observe( Breakpoints.Small ).subscribe( r => this.smallClass = r.matches );
    brkPntObs.observe( Breakpoints.Medium ).subscribe( r => this.mediumClass = r.matches );
    brkPntObs.observe( Breakpoints.Large ).subscribe( r => this.largeClass = r.matches );
    brkPntObs.observe( Breakpoints.XLarge ).subscribe( r => this.xLargeClass = r.matches );
  }

  @ViewChild('newProjectWarnTpl') newProjectWarnTpl: TemplateRef<any>;
  newProject()
  {
    this.clientSrv.get().pipe
    (
      switchMap( ({ progress }) => progress > 0
        ? this.dialog.open
        (
          this.newProjectWarnTpl,
          { data: { percent: (progress * 100 / 7).toFixed(0) } }
        ).afterClosed().pipe( takeWhile( result => !!result ) )
        : of(true)
      ),
      switchMap( _ => this.clientSrv.delete() ),
      switchMap( _ => this.router.navigateByUrl(`/${FORM_PATH}`) )
    ).subscribe();

  }
  edit() { this.router.navigateByUrl(`/${FORM_PATH}`); }

  @ViewChild('deleteDialogTmpl') deleteDialogTmpl: TemplateRef<any>;
  delete( item: any )
  {
    return this.dialog.open( this.deleteDialogTmpl, { data: item } ).afterClosed().pipe
    (
      takeWhile( result => !!result ),
      switchMap( _ => this.clientSrv.delete() ),
      switchMap( _ => this.router.navigate(['.'], { relativeTo: this.route }) )
    ).subscribe( );
  }
  @ViewChild('submitDialogTmpl') submitDialogTmpl: TemplateRef<any>;

  submitting = false;
  submit( { data }: any )
  {
    return this.dialog.open( this.submitDialogTmpl ).afterClosed().pipe
    (
      takeWhile( result => this.submitting = !!result ),
      switchMap( _ => this.apiSrv.submit( data ) ),
      finalize( () => this.submitting = false ),
      switchMap( ({ code, messages }) =>
      {
        if ( code > 0 ) return this.clientSrv.delete();
        this.snackBar.open( Array.isArray(messages)
          ? messages.join(' ')
          : Object.entries( messages )
            .map( ([ field, message ]: [ string, string ]) =>
            `${field.replace(/^\w/, c => c.toUpperCase()).replace(/[-_\s]([a-z])/g, ( _, v ) => ` ${v.toUpperCase()}`)}: ${message}` )
            .join('. ')
          , undefined, { duration: 3000 } );
        throwError( new Error('Server error!') );
      } ),
      switchMap( _ => this.router.navigate(['.'], { relativeTo: this.route }) )
    ).subscribe();
  }
}
