import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable, MonoTypeOperatorFunction, of, zip, throwError } from 'rxjs';
import { map, publishReplay, refCount, switchMap, tap, catchError, finalize } from 'rxjs/operators';
import { BriefStateMdl, BriefMdl } from './brief-mdl';
import { extend } from '../+';
import { ABriefSrv } from './a.srv';
import { BriefStateFty } from './brief-state.fty';
import  * as moment from 'moment';

const cache = (): [ MonoTypeOperatorFunction<any>, MonoTypeOperatorFunction<any> ] => [ publishReplay(1), refCount() ];
const META_URL = '/api/metadata/';
const flatMapApiValues = ( a: any[], { key, value, children } ) => [ ...a, { key, value }, ...children ];
const notEmpty = ({ id, value }) => !!`${id}${value}`.trim();
@Injectable()
export class BriefApiSrv extends ABriefSrv
{
  constructor( private readonly http: HttpClient, bf: BriefStateFty ) { super(bf); }
  list(): Observable<BriefStateMdl[]>
  {
    return this.http.get<any>('/api/projectrequest/list').pipe
    (
      map( ({ data }) => data.data.reverse() ),
      switchMap( data => this.functions.pipe( map( functions => ({ data, functions }) ) ) ),
      map( ( { data, functions } ) => data.map( (brief: any) =>
      {
        brief.function = functions.reduce( flatMapApiValues, [] ).find( ( { key } ) => key === brief.function );
        return this.briefStateFty.create( { data: extend( new BriefMdl, this.toInternal(brief) ), ...brief } );
      }) ),
    );
  }
  private projectCaches = {};
  private getProject(id: number)
  {
    return this.projectCaches[id] || ( this.projectCaches[id] = this.http.get<any>(`/api/projectrequest/${id}`).pipe(...cache()) );
  }
  get( id: number ): Observable<BriefStateMdl>
  {
    return zip
    (
      this.contractTypes,
      this.functions,
      this.industryList,
      this.revenueList,
      this.keycompetencesMapping,
      this.keycompetences,
      this.personalRequirements,
      this.specialRequirements,
      this.languages,
      this.getProject(id),
    ).pipe
    (
      catchError( e => throwError(e) ),
      map
      (([
        contractTypes,
        functions,
        industries,
        revenues,
        keyCompetencesMapping,
        keyCompetences,
        personalRequirements,
        specialRequirements,
        languages,
        serverBrief,
      ]) =>
      {
        const brief = extend( {}, serverBrief );
        brief.data.function = functions.reduce( flatMapApiValues, [] ).find( ( { key } ) => key === brief.data.function );
        brief.data.contract_type = contractTypes.reduce( flatMapApiValues, [] ).find( ( { key } ) => key === brief.data.contract_type );
        brief.data.industry = industries.reduce( flatMapApiValues, [] ).find( ( { key } ) => key === brief.data.industry );
        brief.data.revenue = revenues.reduce( flatMapApiValues, [] ).find( ( { key } ) => key === brief.data.revenue );
        brief.data.start = moment.utc(brief.data.start).toISOString();

        const selectableKeyCompetences = (keyCompetencesMapping[brief.data.function && brief.data.function.key] || [])
          .map( (key: string) => ({ id: key, value: keyCompetences[key] }) )
          .sort( (a: {value: string}, b: {value: string}) => a.value < b.value ? -1 : 1 );
        // tslint:disable: no-bitwise
        brief.data.keyCompetences = selectableKeyCompetences.map( ({ id: kId }) => !!~(brief.data.keycompetences || []).indexOf(kId) );

        brief.data.personal_requirements = personalRequirements.filter(notEmpty).map( ({ id: kId }) => !!~brief.data.personal_requirements.indexOf(kId) );
        brief.data.specialSituations = specialRequirements.filter(notEmpty).map( ({ id: kId }) => !!~brief.data.special_requirements.indexOf(kId) );
        brief.data.languages = languages.filter(notEmpty).map( ({ id: kId }) => !!~brief.data.languages.indexOf(kId) );
        brief.data.days_per_week = +brief.data.days_per_week;
        brief.data.budget = brief.data.responsibility_budget;
        brief.data.employeeResponsibilityCount = '' + brief.data.responsibility_employees;
        return brief;
      } ),
      map( brief => this.briefStateFty.create( extend( new BriefMdl, this.toInternal(brief.data) ) ) )
    );
  }

  private mapFieldsForSubmit( briefData: Partial<BriefMdl> )
  {
    return zip
    (
      this.languages,
      this.specialRequirements,
      this.personalRequirements,
      this.keycompetencesMapping,
      this.keycompetences,
      this.selectionMethods,
    ).pipe
    (
      catchError( e => throwError(e) ),
      // map from server ids and other fill-ins
      map( ([ languages, specialRequirements, personalRequirements, keyCompetencesMapping, keyCompetences, selectionMethods ]) =>
      {
        const submitProject = this.toExternal(extend( {}, briefData)) as any;
        submitProject.selection_methods = (briefData.selectionMethod || [])
          .map( (selected: boolean, index: number) => selected && selectionMethods[index].id )
          .filter( (l: any) => !!l );
        submitProject.languages = (briefData.languages || [])
          .map( (selected: boolean, index: number) => selected && languages[index].id )
          .filter( (l: any) => !!l );
        submitProject.special_requirements = (briefData.specialSituations || [])
          .map( (selected: boolean, index: number) => selected && specialRequirements[index].id )
          .filter( (s: any) => !!s );
        submitProject.personal_requirements = (briefData.personalRequirements || [])
          .map( (selected: boolean, index: number) => selected && personalRequirements[index].id )
          .filter( (s: any) => !!s );
        const competencesList = ( keyCompetencesMapping[ briefData.function && briefData.function.key ] || [] )
          .map( (key: string) => ({ id: key, value: keyCompetences[key] }) )
          .sort( (a: {value: string}, b: {value: string}) => a.value < b.value ? -1 : 1 );
        submitProject.keycompetences = (briefData.keyCompetences || [])
          .map( (selected: boolean, index: number) => selected && competencesList[index] )
          .filter( (k: any) => !!k )
          .map( ({ id }) => id );
        submitProject.contract_type = briefData.contractType && briefData.contractType.key;
        submitProject.function = ((briefData.function && briefData.function.key) || '');
        submitProject.industry = ((briefData.industry && briefData.industry.key) || '');
        submitProject.revenue = ((briefData.revenue && briefData.revenue.key) || '');
        submitProject.files = Array.isArray(submitProject.files) ? submitProject.files : [];
        submitProject.responsibility_budget = '' + (briefData.budget || '');
        submitProject.responsibility_employees = '' + (briefData.employeeResponsibilityCount || '');
        return submitProject;
      } )
    );
  }

  private mapFilesForSubmit( briefData: Partial<BriefMdl> ): Observable<any>
  {
    return zip( of(false), ...( ( Array.isArray(briefData.files) && briefData.files
      .map( (file: File) =>
      new Observable<any>( observer =>
      {
        const reader = new FileReader();
        reader.onload = event => observer.next( { file, data: (event.target as any).result } );
        reader.onerror = error => observer.error(error);
        reader.onabort = error => observer.error(error);
        reader.onloadend = () => observer.complete();
        reader.readAsDataURL( new Blob([ new Uint8Array((file as any).data) ], { type: file.type }) );
      } ).pipe
      (
        map( ( { data }) =>
        ({
          lastMod : file.lastModified,
          lastModDate: file.lastModified,
          name : file.name,
          size : file.size,
          type : file.type,
          data : data
        }) )
      ) )
      ) || [] )
    ).pipe( map( (files: Array<File|boolean>) => files.filter( file => !!file ) ) );
  }

  submit( briefData: Partial<BriefMdl> )
  {
    return this.mapFieldsForSubmit( briefData ).pipe
    (
      switchMap( b => this.mapFilesForSubmit( b ).pipe( map( files => extend( b, {files } ) ) ) ),
      switchMap( b => this.http.post<any>('/api/projectrequest', b ) ),
    );
  }

  private _mapMetadata( { data }: { data: any } )
  {
    const dataValues = Object.values( data ) as any;
    return Object.keys( data ).map( ( key, index ) =>
    {
      let children = [];
      const childKeys = Object.keys(dataValues[index].children);
      const childValues = Object.values(dataValues[index].children) as any[];
      if ( childKeys.length )
       children = childKeys.map( ( childKey: any, cIndex: any ) => ({ key: `${key}.${childKey}`, value: childValues[cIndex].value } ) );
      return { key, value: dataValues[index].value, children };
    });
  }

  private _mapObjectToArray( { data }: { data: any })
  {
    return Object.entries(data).map( ([ id, value ]) => ( { id, value} ) );
  }

  metadata: any = {};
  private _industryLists: Observable<any>;
  get industryList()
  {
    if ( !this._industryLists )
      this._industryLists = this.http.get(`${META_URL}remote/tree/logma_tree_industry_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._industryLists;
  }

  private _revenueList: Observable<any>;
  get revenueList()
  {
    if ( !this._revenueList )
      this._revenueList = this.http.get(`${META_URL}remote/tree/logma_corp_fitness_revenue_list`)
      .pipe( map(this._mapMetadata), ...cache() );

    return this._revenueList;
  }

  private _contractTypes: Observable<any>;
  get contractTypes()
  {
    if ( !this._contractTypes )
      this._contractTypes = this.http.get(`${META_URL}remote/tree/logma_target_positions_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._contractTypes;
  }

  private _functions: Observable<any>;
  get functions()
  {
    if ( !this._functions )
      this._functions = this.http.get(`${META_URL}remote/tree/logma_tree_function_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._functions;
  }

  private _keycompetencesMapping: Observable<any>;
  get keycompetencesMapping()
  {
    if ( !this._keycompetencesMapping )
      this._keycompetencesMapping = this.http.get<{ data: any }>(`${META_URL}local/keycompetences_functions`)
        .pipe( map(({ data }: { data: any }) => data ), ...cache() );

    return this._keycompetencesMapping;
  }

  private _keycompetences: Observable<any>;
  get keycompetences()
  {
    if ( !this._keycompetences )
      this._keycompetences = this.http.get<{ data: any }>(`${META_URL}remote/logma_tree_keycompetences_list`)
        .pipe( map(({ data }: { data: any }) => data ), ...cache() );

    return this._keycompetences;
  }

  private _personalRequirements: Observable<any>;
  get personalRequirements()
  {
    if ( !this._personalRequirements )
      this._personalRequirements = this.http.get<{ data: any }>(`${META_URL}remote/logma_pers_competence_list`)
        .pipe( map( this._mapObjectToArray ), ...cache() );

    return this._personalRequirements;
  }

  private _specialRequirements: Observable<any>;
  get specialRequirements()
  {
    if (!this._specialRequirements)
      this._specialRequirements = this.http.get<{ data: any }>(`${META_URL}local/special_requirements`)
        .pipe( map( this._mapObjectToArray ), ...cache() );

    return this._specialRequirements;
  }

  private _languages: Observable<any>;
  get languages()
  {
    if ( !this._languages )
      this._languages = this.http.get<{ data: any }>(`${META_URL}local/languages`)
        .pipe( map( this._mapObjectToArray ), ...cache() );

    return this._languages;
  }

  private _selectionMethods: Observable<any>;
  get selectionMethods()
  {
    if ( !this._selectionMethods )
      this._selectionMethods = this.http.get(`${META_URL}local/selection_methods`)
        .pipe( map(this._mapObjectToArray), ...cache() );

    return this._selectionMethods;
  }

}
