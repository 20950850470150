import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  Resolve,
  Router,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { BriefApiSrv } from './api.srv';
import { BriefClientSrv } from './client.srv';
import { BriefFormCom } from './form.com';
import {
  ABriefFormStep,
  BriefFormStep1Com,
  BriefFormStep2Com,
  BriefFormStep3Com,
  BriefFormStep4Com,
  BriefFormStep5Com,
  BriefFormStep6Com,
} from './form';
import { BriefStepType } from './brief-mdl';
import { switchMap, map, tap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';

export const FORM_PATH = 'briefing';
@Injectable()
export class CurrentStepGuard implements CanActivateChild, CanActivate
{
  constructor
  (
    private readonly router: Router,
    private readonly apiSrv: BriefApiSrv,
    private readonly clientSrv: BriefClientSrv,
    private readonly route: ActivatedRoute,
  ) {}
  canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot )
  {
    const paramId = childRoute.params.id || childRoute.parent.params.id;
    let startObs = this.clientSrv.get();
    if ( paramId ) startObs = this.apiSrv.get( paramId );
    return startObs.pipe
    (
      switchMap( ({ progress, steps, id }) =>
      {
        const wantPath = childRoute.parent.routeConfig.children.findIndex( ({ path }) => path === childRoute.routeConfig.path );
        if ( progress < wantPath / steps.length && !id )
          return ( this.router.navigate( [
            FORM_PATH,
            childRoute.parent.params,
            `step${ Math.round( progress * steps.length ) + 1 }` ], { relativeTo: this.route } ), of( false ) );
        return of( true );
      })
    );
  }
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot )
  {
    const actualUrl = state.url.replace(/;[^\/]+/, '');
    if ( actualUrl !== `/${FORM_PATH}` ) return of(true);
    const paramId = next.params.id;
    let startObs = this.clientSrv.get();
    if ( paramId ) startObs = this.apiSrv.get( paramId );
    return startObs.pipe
    (
      switchMap( ({ progress }) =>
        ( this.router.navigate([ 'briefing', next.params, `step${ progress + 1 }` ],
          { relativeTo: this.route  } ), of( false ) ) )
    );
  }
}

@Injectable()
export class BriefResolver implements Resolve<any>
{
  constructor
  (
    private readonly apiSrv: BriefApiSrv,
    private readonly clientSrv: BriefClientSrv,
  ) {}
  resolve( route: ActivatedRouteSnapshot )
  {
    if ( route.params.id ) return this.apiSrv.get( route.params.id );
    return this.clientSrv.get();
  }
}

@Injectable()
export class BriefStepDeactivate implements CanDeactivate<ABriefFormStep<BriefStepType>>
{
  constructor( private readonly clientSrv: BriefClientSrv ) {}
  canDeactivate
  (
    component: ABriefFormStep<BriefStepType>,
  ): Observable<boolean>
  {
    return component.form.dirty ? component.unsavedWarnTpl().pipe
    (
      switchMap<any, any>( ok => undefined === ok
        ? of( false )
        : ok ? this.clientSrv.saveData( component.form.value ).pipe( map( _ => true ) ) : of(true)
      ),
      tap( ok => ok && component.form.markAsPristine() )
    ) : of( true );
  }
}

export const BRIEF_ROUTES: Routes =
[
  {
    path: FORM_PATH,
    component: BriefFormCom,
    runGuardsAndResolvers: 'always',
    canActivate: [ CurrentStepGuard ],
    canActivateChild: [ CurrentStepGuard ],
    resolve: { project: BriefResolver },
    children:
    [
      { path: 'step1', component: BriefFormStep1Com, canDeactivate: [BriefStepDeactivate] },
      { path: 'step2', component: BriefFormStep2Com, canDeactivate: [BriefStepDeactivate] },
      { path: 'step3', component: BriefFormStep3Com, canDeactivate: [BriefStepDeactivate] },
      { path: 'step4', component: BriefFormStep4Com, canDeactivate: [BriefStepDeactivate] },
      { path: 'step5', component: BriefFormStep5Com, canDeactivate: [BriefStepDeactivate] },
      { path: 'step6', component: BriefFormStep6Com, canDeactivate: [BriefStepDeactivate] },
      { path: '**', redirectTo: 'step1', pathMatch: 'full' },
    ]
  },
];
