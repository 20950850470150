import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ABriefFormStep } from './a.com';
import { BriefStep3Type, BriefStep3Fields } from '../brief-mdl';
import { isMoment } from 'moment';
const { required } = Validators;

@Component
({
  selector: 'brief-form-step3',
  templateUrl: './step3.com.pug',
  styleUrls: ['./+.scss', './step3.com.scss'],
})
export class BriefFormStep3Com extends ABriefFormStep<BriefStep3Type> implements OnInit, AfterViewInit
{
  form: FormGroup;
  minDate = new Date();
  fieldKeys = BriefStep3Fields;
  dateOpen() {
    this.fields[this.fieldKeys.start].clearValidators();
    this.fields[this.fieldKeys.start].updateValueAndValidity();
  }
  dateClosed() {
    this.fields[this.fieldKeys.start].setValidators(required);
    this.fields[this.fieldKeys.start].updateValueAndValidity();
  }
  ngOnInit()
  {
    const [fields, fieldKeys] = [this.fields, this.fieldKeys];
    this.form = new FormGroup
    ({
      [fieldKeys.start]: ( fields[fieldKeys.start] = new FormControl( '', required ) ),
      [fieldKeys.rate]: ( fields[fieldKeys.rate] = new FormControl( '', required ) ),
      [fieldKeys.duration]: ( fields[fieldKeys.duration] = new FormControl( '' ) ),
      [fieldKeys.location]: (fields[fieldKeys.location] = new FormControl('', required)),
      [fieldKeys.daysPerWeek]: (fields[fieldKeys.daysPerWeek] = new FormControl(0, required)),
    });
  }
  ngAfterViewInit()
  {
    super.ngAfterViewInit();
    this.valueChangeSub.add
    (
      this.fields.start.valueChanges.subscribe( start => isMoment(start)
        && (this.fields.start as FormControl).setValue( start.toISOString(),
          { onlySelf: true, emitModelToViewChange: false, emitViewToModelChange: false, emitEvent: false } ) )
    );
  }
}
