import { Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { from, Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { BriefClientSrv } from '../client.srv';

const BRIEF_STATE_STORAGE_KEY = 'myHead-step-storage';
@Injectable()
export class StepStateSrv
{
  private _data = {};
  constructor
  (
    private readonly store: NgForage,
    private readonly clientSrv: BriefClientSrv
  )
  {
    from(this.store.getItem( BRIEF_STATE_STORAGE_KEY )).pipe( tap( d => this._data = d || {} ) ).subscribe();
  }
  set( key: string, value: any )
  {
    // special case for files, save in clientSrv storage directly
    return ( key === 'files' && value && (value as File[]).every( file => file instanceof File ) ? this.clientSrv.saveFiles( value ) : of(null) ).pipe
    (
      switchMap( files =>
      {
        if ( Array.isArray(files) && files.every( file => file.data && file.data instanceof ArrayBuffer ) )
          this._data[key] = files.map( file => { const { data, ...fileProps } = file; return fileProps; } );
        else this._data[key] = value;
        return from( this.store.setItem( BRIEF_STATE_STORAGE_KEY, this._data ) );
      })
    );

  }
  get( key: string ): Observable<any>
  {
    return from(this.store.getItem( BRIEF_STATE_STORAGE_KEY )).pipe( map( d => ( d && d[key] || undefined ) ) );
  }
  clear()
  {
    from(this.store.setItem( BRIEF_STATE_STORAGE_KEY, {} )).pipe( tap( _ => this._data = {} )).subscribe();
  }
}
