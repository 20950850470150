import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Response } from '@angular/http';
import { map, publishReplay, refCount, tap, catchError } from 'rxjs/operators';
import { Observable, MonoTypeOperatorFunction, of, OperatorFunction } from 'rxjs';
import * as _ from 'lodash';
import { GET, AbstractApiClient, Cache, BaseUrl } from 'angular2-rest-client';

const cache = (): [ MonoTypeOperatorFunction<any>, MonoTypeOperatorFunction<any>, OperatorFunction<any, any> ] =>
  [ publishReplay(1), refCount(), catchError( e => of(e) ) ];
const META_URL = '/api/metadata/';

@Injectable()
@BaseUrl('/api/metadata/')
export class ProjectWizardSrv extends AbstractApiClient
{
  private _mapMetadata( { data }: { data: any } )
  {
    const dataValues = Object.values( data ) as any;
    return Object.keys( data ).map( ( key, index ) =>
    {
      let children = [];
      const childKeys = Object.keys(dataValues[index].children);
      const childValues = Object.values(dataValues[index].children) as any[];
      if ( childKeys.length )
       children = childKeys.map( ( childKey: any, cIndex: any ) => ({ key: `${key}.${childKey}`, value: childValues[cIndex].value } ) );
      return { key, value: dataValues[index].value, children };
    });
  }

  private _mapObjectToArray( { data }: { data: any })
  {
    return Object.entries(data).map( ([ id, value ]) => ( { id, value} ) );
  }

  metadata: any = {};
  private _industryLists: Observable<any>;
  get industryList()
  {
    if ( !this._industryLists )
      this._industryLists = this.newHttp.get(`${META_URL}remote/tree/logma_tree_industry_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._industryLists;
  }

  private _revenueList: Observable<any>;
  get revenueList()
  {
    if ( !this._revenueList )
      this._revenueList = this.newHttp.get(`${META_URL}remote/tree/logma_corp_fitness_revenue_list`)
      .pipe( map(this._mapMetadata), ...cache() );

    return this._revenueList;
  }

  private _contractTypes: Observable<any>;
  get contractTypes()
  {
    if ( !this._contractTypes )
      this._contractTypes = this.newHttp.get(`${META_URL}remote/tree/logma_target_positions_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._contractTypes;
  }

  private _functions: Observable<any>;
  get functions()
  {
    if ( !this._functions )
      this._functions = this.newHttp.get(`${META_URL}remote/tree/logma_tree_function_list`)
        .pipe( map(this._mapMetadata), ...cache() );

    return this._functions;
  }

  private _keycompetencesMapping: Observable<any>;
  get keycompetencesMapping()
  {
    if ( !this._keycompetencesMapping )
      this._keycompetencesMapping = this.newHttp.get<{ data: any }>(`${META_URL}local/keycompetences_functions`)
        .pipe( map(({ data }: { data: any }) => data ), ...cache() );

    return this._keycompetencesMapping;
  }

  private _keycompetences: Observable<any>;
  get keycompetences()
  {
    if ( !this._keycompetences )
      this._keycompetences = this.newHttp.get<{ data: any }>(`${META_URL}remote/logma_tree_keycompetences_list`)
        .pipe( map(({ data }: { data: any }) => data ), ...cache() );

    return this._keycompetences;
  }

  // private _personalRequirements: Observable<any>;
  // get personalRequirements()
  // {
  //   if ( !this._personalRequirements )
  //     this._personalRequirements = this.newHttp.get<{ data: any }>(`${META_URL}remote/logma_pers_competence_list`)
  //       .pipe( tap( _ => { debugger; }), map( this._mapObjectToArray ), ...cache() );
  //     // TODO:
  //     // .pipe( catchError( e => of(e) ), publishReplay(1, 1000), refCount(), take(1), map( val => { debugger; return val; }) );
  //   return this._personalRequirements;
  // }

  @Cache(2.628e+9)
  @GET(`remote/logma_pers_competence_list`)
  private _personalRequirements(): Observable<Response> { return; }
  get personalRequirements()
  {
    return this._personalRequirements().pipe( map( r => r.json() ), map( this._mapObjectToArray ) );
  }

  private _specialRequirements: Observable<any>;
  get specialRequirements()
  {
    if (!this._specialRequirements)
      this._specialRequirements = this.newHttp.get<{ data: any }>(`${META_URL}local/special_requirements`)
        .pipe( map( this._mapObjectToArray ), ...cache() );

    return this._specialRequirements;
  }

  private _languages: Observable<any>;
  get languages()
  {
    if ( !this._languages )
      this._languages = this.newHttp.get<{ data: any }>(`${META_URL}local/languages`)
        .pipe( map( this._mapObjectToArray ), ...cache() );

    return this._languages;
  }

  private _selectionMethods: Observable<any>;
  get selectionMethods()
  {
    if ( !this._selectionMethods )
      this._selectionMethods = this.newHttp.get(`${META_URL}local/selection_methods`)
        .pipe( map(this._mapObjectToArray), ...cache() );

    return this._selectionMethods;
  }

  constructor( private readonly newHttp: HttpClient, protected http: Http ) {
    super(http)
  }
}
