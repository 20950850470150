
  <h1 mat-dialog-title i18n="@@title-delete-data">Delete data?</h1>
  <div mat-dialog-content>
    <p i18n="@@text-want-to-delete">Do you want to delete?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial=focusNo [mat-dialog-close]="false" i18n="@@button-icon-no">
     <mat-icon svgIcon="fa:times"></mat-icon>NO
    </button>
    <button mat-flat-button color="primary" cdkFocusInitial=focusYes [mat-dialog-close]="true" i18n="@@button-yes">Ok</button>
  </div>
  