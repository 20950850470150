import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FieldErrorCom } from './field-error.com';
import { HttpModule } from '@angular/http';

@NgModule
({
  declarations:
  [
    FieldErrorCom,
  ],
  imports:
  [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
  ],
  exports:
  [
    HttpClientModule,
    FieldErrorCom,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
  ],
})
export class SharedMod {}
