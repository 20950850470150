import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { timer } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component
({
  selector: 'main-auth-login-popup',
  templateUrl: './login-popup.com.pug',
  styleUrls: ['./login-popup.com.scss']
})
export class LoginPopupCom implements AfterViewInit
{
  @ViewChild('dialogTpl') dialogTpl: TemplateRef<any>;
  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) { }
  ngAfterViewInit()
  {
    timer(1).pipe
    (
      switchMap( _ => this.dialog.open( this.dialogTpl ).afterClosed() ),
      tap( _ => this.router.navigate([ { outlets: { popup: null } } ]) )
    ).subscribe();
  }
}
