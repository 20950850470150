<div class="col">
<div class="content-container"><label class="xs" i18n="@@label-step4">Step 4</label><h1 i18n="@@title-tech-req"> Fachliche Anforderungen </h1>
<p i18n="@@description-project-step4"> Bitte wählen Sie die  fachlichen Anforderungen an den Experten aus. Wenn Ihnen Eigenschaften fehlen, so können Sie diese unter Sonstiges auf der Folgeseite angeben. </p>
</div>
</div>
<div class="col xs-12" [formGroup]="form">
<div class="form-container">
<h2 i18n="@@label-tech-req">Anforderungen fachlich*</h2>
<div class="form-group flex-col row-inner">
<div class="col checkboxes"><ng-container formArrayName="keyCompetences" *ngIf="selectableKeyCompetences; else loadingTpl"><ng-container *ngFor=" let c of selectableKeyCompetences; index as i "><mat-checkbox [formControlName]="i">{{c.value}}</mat-checkbox></ng-container></ng-container><mat-error class="field-error" *ngIf="fields.keyCompetences.dirty" [control]="fields.keyCompetences"></mat-error></div>
</div>
</div>
</div><ng-template #loadingTpl><ng-container i18n="@@loading">Loading...</ng-container></ng-template><div class="col"><div class="form-container"><div class="button-group"><div *ngIf="!submitted; else onlyCloseButtonTpl"><button mat-button type="button" (click)="cancel()" i18n="@@button-cancel"><mat-icon svgIcon="fa:trash-alt"></mat-icon> Delete……</button><button mat-button type="button" (click)="save()" i18n="@@button-save"><mat-icon svgIcon="fa:save"></mat-icon> Save………</button></div><ng-template #onlyCloseButtonTpl><button mat-button type="button" (click)="cancel()" i18n="@@button-close"><mat-icon>close</mat-icon> Close………</button></ng-template><div><button mat-button type="button" color="primary" (click)="prev()" i18n="@@button-previous-step"><mat-icon>keyboard_arrow_left</mat-icon> Previous… Step………</button><button mat-button *ngIf="!submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-step-optional-icon">Next… (Optional) <mat-icon>keyboard_arrow_right</mat-icon></button><button mat-button *ngIf="submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-icon">Next…  <mat-icon>keyboard_arrow_right</mat-icon></button></div></div></div><div class="full" *ngIf="!submitted"><p class="error" *ngIf="submitError">Server error!</p><button class="submit" mat-flat-button type="button" color="primary" (click)="submit()" [disabled]="submitting"><span i18n="@@button-submit">Submit…</span><div class="loading"><mat-spinner diameter="30" strokeWidth="3"></mat-spinner></div></button></div></div><div class="col">
<div class="content-container">
<small i18n="@@text-project-step4-outro"> Mit "Zwischenspeichern & Beenden" unterbrechen Sie das Projektbriefing und speichern die Daten für die spätere Weiterbearbeitung. Auf Ihrer passwortgeschützten myHead-Seite können Sie jedes Projektbriefing wieder aufrufen und fertigstellen - egal ob vom Handy, iPad oder am Rechner. Mit "Nächster Schritt" gelangen Sie zur folgenden Seite des Briefings. </small>
</div>
</div>