import { Component } from '@angular/core';
import { AuthSrv, AuthError } from './srv';
import { switchMapTo, finalize, take, switchMap } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
const { required, email } = Validators;
@Component
({
  selector: 'main-auth-login',
  templateUrl: './login.com.pug',
  styleUrls: ['./+.scss'],
})
export class LoginCom
{
  hideLoginPass = true;
  serverLoginErrors: string[] = [];
  serverMessages: string[] = [];
  loggingIn = false;
  form: FormGroup;
  email: FormControl;
  password: FormControl;
  formReset: FormGroup;
  emailReset: FormControl;
  constructor( private readonly authSrv: AuthSrv )
  {
    this.form = new FormGroup
    ({
      email: ( this.email = new FormControl( '', [ required, email ] ) ),
      password: ( this.password = new FormControl( '', required ) )
    });
    this.formReset = new FormGroup
    ({
      emailReset: ( this.emailReset = new FormControl( '', [ required, email ] ) ),
    });
  }
// tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.email.valueChanges.subscribe(contactEmailLabel => {
      if (this.email.value !== null) {
        if (this.email.value !== this.email.value.trim()) {
          this.form.controls['email'].setValue(contactEmailLabel.trim());
        }
      }
    });
  }
  login()
  {
    if ( this.form.invalid ) return;
    this.loggingIn = true;
    this.serverLoginErrors = [];
    this.serverMessages = [];
    this.authSrv.redirectUrl = '/dashboard';
    this.authSrv.logout().pipe
    (
      switchMapTo( this.authSrv.login( this.email.value, this.password.value ) ),
      finalize( () => { this.loggingIn = false; } )
    )
    .subscribe( undefined, e => this.serverLoginErrors = e instanceof AuthError ? e.data : [] );
  }
  showResetPass = false;
  resetPass()
  {
    if ( this.formReset.invalid  ) return;
    this.serverLoginErrors = [];
    this.serverMessages = [];
    this.authSrv.requestPassReset( this.emailReset.value )
    .subscribe( ( { messages } ) => this.serverMessages = messages, e => this.serverLoginErrors = e instanceof AuthError ? e.data : [] );
  }
}
