<div class="col"><div class="content-container"><label class="xs" i18n="@@label-step2">Step 2</label><h1 i18n="@@title-contact-4-candidate-search">Contact Person fot the Candidate Search</h1><p i18n="@@description-project-step2">Um direkt mit Ihnen kommunizieren zu können benötigen wir einen Ansprechpartner und die Kontaktdaten, damit wir bei Rückfragen zu Ihren Suchanforderungen schnell und unkompliziert den Kontakt aufnehmen können.</p></div></div><div class="col"><div class="divider-steps"><hr></div></div><div class="col">
<div class="form-container" [formGroup]="form">
<div class="form-group flex-col row-inner">
<div class="col xs-12 m-12"><mat-form-field appearance="outline"><mat-label i18n="@@label-project-name">Project Name</mat-label><input matInput required [formControl]="fields.projectName"><mat-error class="field-error" [control]="fields.projectName"></mat-error></mat-form-field></div>
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-contract-type">Contract Type</mat-label><mat-icon matSuffix (click)="openAutoPanel( $event, contractTypeAutoTrigger )">keyboard_arrow_down</mat-icon><input type="text" matInput [formControl]="fields.contractType" required (blur)="autoClosed(fields.contractType)" [matAutocomplete]="autoContractType" #contractTypeAutoTrigger="matAutocompleteTrigger" [matAutocompleteDisabled]="fields.contractType.disabled"><mat-autocomplete #autoContractType="matAutocomplete" [displayWith]="autoDisplayValue"><mat-option *ngFor=" let contract of contractTypeList | async " [value]="contract">{{contract.value}}</mat-option></mat-autocomplete><mat-error class="field-error" [control]="fields.contractType"></mat-error></mat-form-field></div>
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-function">Function</mat-label><mat-icon matSuffix (click)="openAutoPanel( $event, functionAutoTrigger )">keyboard_arrow_down</mat-icon><input type="text" matInput [formControl]="fields.function" required (blur)="autoClosed(fields.function)" [matAutocomplete]="autoFunction" #functionAutoTrigger="matAutocompleteTrigger" [matAutocompleteDisabled]="fields.function.disabled"><mat-autocomplete #autoFunction="matAutocomplete" [displayWith]="autoDisplayValue"><mat-optgroup *ngFor=" let function of functionList | async " [label]="function.value"><mat-option *ngFor=" let subFunction of function.children " [value]="subFunction">{{subFunction.value}}</mat-option></mat-optgroup></mat-autocomplete><mat-error class="field-error" [control]="fields.function"></mat-error></mat-form-field></div>
</div>
<div class="form-group flex-col row-inner"><div class="col xs-12 m-12"><mat-form-field appearance="outline"><mat-label i18n="@@label-project-description">Project Description</mat-label><textarea matInput required [formControl]="fields.description" matTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea><mat-error class="field-error" [control]="fields.description"></mat-error></mat-form-field></div></div>
</div>
</div><div class="col"><div class="form-container"><div class="button-group"><div *ngIf="!submitted; else onlyCloseButtonTpl"><button mat-button type="button" (click)="cancel()" i18n="@@button-cancel"><mat-icon svgIcon="fa:trash-alt"></mat-icon> Delete……</button><button mat-button type="button" (click)="save()" i18n="@@button-save"><mat-icon svgIcon="fa:save"></mat-icon> Save………</button></div><ng-template #onlyCloseButtonTpl><button mat-button type="button" (click)="cancel()" i18n="@@button-close"><mat-icon>close</mat-icon> Close………</button></ng-template><div><button mat-button type="button" color="primary" (click)="prev()" i18n="@@button-previous-step"><mat-icon>keyboard_arrow_left</mat-icon> Previous… Step………</button><button mat-button *ngIf="!submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-step-optional-icon">Next… (Optional) <mat-icon>keyboard_arrow_right</mat-icon></button><button mat-button *ngIf="submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-icon">Next…  <mat-icon>keyboard_arrow_right</mat-icon></button></div></div></div><div class="full" *ngIf="!submitted"><p class="error" *ngIf="submitError">Server error!</p><button class="submit" mat-flat-button type="button" color="primary" (click)="submit()" [disabled]="submitting"><span i18n="@@button-submit">Submit…</span><div class="loading"><mat-spinner diameter="30" strokeWidth="3"></mat-spinner></div></button></div></div><div class="col">
<div class="content-container">
<small i18n="@@text-project-step2-outro">Mit "Zwischenspeichern" unterbrechen Sie das Projektbriefing und speichern die Daten für die spätere Weiterbearbeitung. Auf Ihrer passwortgeschützten myHead-Seite können Sie jedes Projektbriefing wieder aufrufen und fertigstellen - egal ob vom Handy, iPad oder am Rechner. Mit "Nächster Schritt" gelangen Sie zur folgenden Seite des Briefings.</small>
</div>
</div>