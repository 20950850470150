import { Observable } from 'rxjs';
import { extend } from '../+';

export class BriefMdl
{
  id?: number;
  companyName: string;
  contactEmail: string;
  contactEmailConfirm: string;
  contactFunction: string;
  contactMobile: string;
  contactName: string;
  contactPhone: string;
  contractType: any;
  daysPerWeek: number;
  description: string;
  duration: number;
  employeeCount: number;
  experience: string;
  files: any[];
  function: any;
  industry: any;
  keyCompetences: boolean[];
  languages: boolean[];
  location: string;
  personalRequirements: boolean[];
  projectName: string;
  type: string[];
  rate: number;
  remark: string;
  budget: number;
  employeeResponsibilityCount: number;
  revenue: any;
  selectionMethod: boolean[];
  specialSituations: boolean[];
  start: Date;
  anon: boolean;
  unit: string;
}
export enum BriefStatuses
{
  DRAFT = 'draft',
  INITIATED = 'initiated',
  REVIEW = 'in_review',
  SEARCHING = 'searching',
  PRESENTED = 'presented',
  INTERVIEW = 'interview',
  COMPLETED = 'completed',
}
export type DataStatus<T extends BriefMdl> = { [P in keyof T]+?: boolean; };
export class BriefStateMdl
{
  id: number;
  uuid: string;
  createdAt: Date;
  modifiedAt: Date;
  status: BriefStatuses;
  progress: any;
  steps: string[];
  submittable = false;
  private _data: BriefMdl;
  set data( value: Partial<BriefMdl> )
  {
    this._data = new BriefMdl;
    this.patchData( value );
  }
  get data() { return this._data; }
  patchData( data: Partial<BriefMdl>, dataStatus?: DataStatus<BriefMdl> )
  {
    this._data = extend( this._data, data );
    this.dataStatus = extend( this.dataStatus, dataStatus || {} );
    this.modifiedAt = new Date;
    return this.data;
  }
  private _dataStatus: DataStatus<BriefMdl> = {};
  set dataStatus( value: DataStatus<BriefMdl> )
  {
    this._dataStatus = value;
  }
  get dataStatus(): DataStatus<BriefMdl> { return this._dataStatus; }

  toObject()
  {
    return {
      createdAt: this.createdAt,
      modifiedAt: this.modifiedAt,
      status: this.status,
      data: this.data,
      dataStatus: this.dataStatus,
      uuid: this.uuid,
    };
  }
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export type BriefStepKeyVal<T extends Partial<BriefMdl>> = { [K in keyof T]: K };
export type BriefStepType = Partial<BriefMdl>;
export type BriefStepFields =  BriefStepKeyVal<Partial<BriefMdl>>;

export type BriefStep1Type = Pick<BriefMdl, 'companyName' | 'contactName' | 'contactFunction' | 'contactPhone' | 'contactEmail' | 'files' | 'type'>;
export const BriefStep1Fields: BriefStepKeyVal<BriefStep1Type> =
{
  companyName: 'companyName',
  contactName: 'contactName',
  files: 'files',
  type: 'type',
  contactFunction: 'contactFunction',
  contactPhone: 'contactPhone',
  contactEmail: 'contactEmail',
};

export type BriefStep1RequiredType = Omit<BriefStep1Type, 'files'>;
export const BriefStep1RequiredFields: BriefStepKeyVal<BriefStep1RequiredType> =
{
  companyName: 'companyName',
  contactName: 'contactName',
  type: 'type',
  contactFunction: 'contactFunction',
  contactPhone: 'contactPhone',
  contactEmail: 'contactEmail'
};

export type BriefStep2Type = Pick<BriefMdl, 'projectName' | 'contractType' | 'function' | 'description'>;
export const BriefStep2Fields: BriefStepKeyVal<BriefStep2Type> =
{
  projectName: 'projectName',
  contractType: 'contractType',
  function: 'function',
  description: 'description'
};
export type BriefStep2RequiredType = Omit<BriefStep2Type, 'contactPhone'>;
export const BriefStep2RequiredFields: BriefStepKeyVal<BriefStep2RequiredType> =
{
  projectName: 'projectName',
  contractType: 'contractType',
  function: 'function',
  description: 'description'
};

export type BriefStep3Type = Pick<BriefMdl, 'rate' | 'start' | 'duration' | 'location' | 'daysPerWeek'>;
export const BriefStep3Fields: BriefStepKeyVal<BriefStep3Type> =
{
  start: 'start',
  rate: 'rate',
  duration: 'duration',
  location: 'location',
  daysPerWeek: 'daysPerWeek',
};
export type BriefStep3RequiredType = Omit<BriefStep3Type, 'duration'>;
export const BriefStep3RequiredFields: BriefStepKeyVal<BriefStep3RequiredType> =
{
  start: 'start',
  rate: 'rate',
  location: 'location',
  daysPerWeek: 'daysPerWeek'
};

export type BriefStep4Type = Pick<BriefMdl, 'keyCompetences'>;
export const BriefStep4Fields: BriefStepKeyVal<BriefStep4Type> =
{
  keyCompetences: 'keyCompetences'
};
export type BriefStep4RequiredType = BriefStep4Type;
export const BriefStep4RequiredFields = BriefStep4Fields;

export type BriefStep5Type = Pick<BriefMdl, 'personalRequirements' | 'specialSituations' | 'languages'>;
export const BriefStep5Fields: BriefStepKeyVal<BriefStep5Type> =
{
  languages: 'languages',
  personalRequirements: 'personalRequirements',
  specialSituations: 'specialSituations',
};
export type BriefStep5RequiredType = BriefStep5Type;
export const BriefStep5RequiredFields = BriefStep5Fields;

export type BriefStep6Type = Pick<BriefMdl, 'employeeResponsibilityCount' | 'budget' | 'experience' | 'anon' | 'remark' | 'selectionMethod'>;
export const BriefStep6Fields: BriefStepKeyVal<BriefStep6Type> =
{
  experience: 'experience',
  budget: 'budget',
  anon: 'anon',
  employeeResponsibilityCount: 'employeeResponsibilityCount',
  remark: 'remark',
  selectionMethod: 'selectionMethod',
};

export type BriefStep6RequiredType = Omit<BriefStep6Type, keyof BriefStep6Type>;
export const BriefStep6RequiredFields: BriefStepKeyVal<BriefStep6RequiredType> = {};
