<div class="col">
<div class="content-container"><label class="xs" i18n="@@label-step6">Step 6</label><h1 i18n="@@title-other-selection-method"> Sonstiges und Auswahlmethode </h1>
<p i18n="@@description-project-step6"> Damit wir Sie bestmöglich im Auswahlprozess unterstützen können, teilen Sie uns bitte mit, wie Sie die Kandidaten aussuchen möchten und ob wir Sie dabei auch persönlich unterstützen sollen. myHead hat aufgrund seiner langjährigen Erfahrung einzigartige Expertise bei der Auswahl, die wir Ihnen gerne zur Verfügung stellen. </p>
</div>
</div>
<form class="col" [formGroup]="form">
<div class="form-container">
<div class="form-group flex-col row-inner">
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-responsibility-employees">Responsibility Employees</mat-label><input matInput [formControl]="fields.employeeResponsibilityCount"><mat-error class="field-error" [control]="fields.employeeResponsibilityCount"></mat-error></mat-form-field></div>
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-responsibility-budget">Responsibility Budget</mat-label><input matInput [formControl]="fields.budget"><mat-error class="field-error" [control]="fields.budget"></mat-error></mat-form-field></div>
</div>
<div class="form-group flex-col row-inner">
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-experience">Experience</mat-label><input matInput [formControl]="fields.experience"><mat-error class="field-error" [control]="fields.experience"></mat-error></mat-form-field></div>
<div class="col xs-12 m-6"><mat-form-field appearance="outline"><mat-label i18n="@@label-remark">Other</mat-label><input matInput [formControl]="fields.remark"><mat-error class="field-error" [control]="fields.remark"></mat-error></mat-form-field></div>
</div>
<div class="form-group flex-col row-inner">
<div class="col xs-12">
<h3 id="selection-method-label" i18n="@@label-selection-method"> Wie möchten Sie die Kandidaten kennenlernen? </h3>
</div>
<div class="col xs-12 m-12"><ng-container formArrayName="selectionMethod" *ngIf="selectionMethodList; else loadingTpl"><ng-container *ngFor=" let method of selectionMethodList; index as i "><mat-checkbox [formControlName]="i">{{method.value}}</mat-checkbox></ng-container></ng-container></div>
</div>
<div class="form-group flex-col row-inner last-form-group">
<div class="col xs-12">
<h3 id="selection-method-label" i18n="@@label-selection-anonymous"> Do you want this to be anonymous? </h3>
</div>
<div class="col xs-12 m-12"><mat-checkbox [formControl]="fields.anon" i18n="@@label-set-anon">Set anonymous</mat-checkbox></div>
</div>
</div>
</form><ng-template #loadingTpl><ng-container i18n="@@loading">Loading...</ng-container></ng-template><div class="col"><div class="form-container"><div class="button-group"><div *ngIf="!submitted; else onlyCloseButtonTpl"><button mat-button type="button" (click)="cancel()" i18n="@@button-cancel"><mat-icon svgIcon="fa:trash-alt"></mat-icon> Cancel………</button><button mat-button type="button" (click)="save()" i18n="@@button-save"><mat-icon svgIcon="fa:save"></mat-icon> Save………</button></div><ng-template #onlyCloseButtonTpl><button mat-button type="button" (click)="cancel()" i18n="@@button-close"><mat-icon>close</mat-icon> Close………</button></ng-template><div><button mat-button type="button" color="primary" (click)="prev()" i18n="@@button-previous-step"><mat-icon>keyboard_arrow_left</mat-icon> Previous… Step………</button><button mat-flat-button *ngIf="!(loggedIn$|async); else submitProjectTpl" type="button" color="primary" routerLink="/auth" i18n="@@button-login-register">Login / Register</button><ng-template #submitProjectTpl><button class="submit" mat-flat-button *ngIf="!submitted; else closeButtonTpl" type="button" color="primary" (click)="submit()" [disabled]="submitting"><span i18n="@@button-submit">Submit…</span><div class="loading"><mat-spinner diameter="30" strokeWidth="3"></mat-spinner></div></button></ng-template><ng-template #closeButtonTpl><button mat-flat-button color="primary" type="button" (click)="cancel()" i18n="@@button-cancel-wo-icon">Close……</button></ng-template></div></div></div></div><div class="col">
<div class="content-container">
<small i18n="@@text-project-step6-outro"> Mit "Zwischenspeichern und Beenden" unterbrechen Sie das Projektbriefing und speichern die Daten für die spätere Weiterbearbeitung. Auf Ihrer passwortgeschützten myHead-Startseite können Sie jedes Projektbriefing wieder aufrufen und fertigstellen. Mit "Nächster Schritt" gelangen Sie zur folgenden Seite. </small>
</div>
</div><ng-template #submitDialogTmpl><h1 mat-dialog-title i18n="@@title-submit-error">Submit Error</h1><div mat-dialog-content><p i18n="@@text-warn-error-submit">There was an error please complete all fields.</p></div><div mat-dialog-actions><button mat-stroke-button color="primary" [mat-dialog-close]="true" i18n="@@button-yes">OK</button></div></ng-template>