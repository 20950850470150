import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @deprecated use {@link BriefApiSrv}
 */
@Injectable()
export class ProjectSrv
{
  /**
   * @deprecated use {@link BriefApiSrv}
   */
  constructor( private readonly http: HttpClient ) {}
  list(): Observable<any>
  {
    return this.http.get('/api/projectrequest/list');
  }
  get( id: number ): Observable<any>
  {
    return this.http.get<any>(`/api/projectrequest/${id}`).pipe( map( ({ data }) => data ));
  }
  submit( project: any ): Observable<any>
  {
    return this.http.post( '/api/projectrequest', project );
  }

  delete( project: any ): Observable<any>
  {
    return of(true);
  }
}
