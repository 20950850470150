import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMod } from '../../shared';
import { BriefMaterialMod } from './material.mod';
import { BriefApiSrv } from './api.srv';
import { BriefClientSrv } from './client.srv';
import { BriefStateFty } from './brief-state.fty';
import { BriefFormCom } from './form.com';
import {
  BriefFormStep1Com,
  BriefFormStep2Com,
  BriefFormStep3Com,
  BriefFormStep4Com,
  BriefFormStep5Com,
  BriefFormStep6Com,
  UnsavedWarnCom,
  DeleteWarnCom,
  FileUploadCom,
} from './form/';
import { StepStateSrv } from './form/step-state.srv';

@NgModule
({
  declarations:
  [
    BriefFormCom,
    BriefFormStep1Com,
    BriefFormStep2Com,
    BriefFormStep3Com,
    BriefFormStep4Com,
    BriefFormStep5Com,
    BriefFormStep6Com,
    FileUploadCom,
    UnsavedWarnCom,
    DeleteWarnCom
  ],
  entryComponents:
  [
    UnsavedWarnCom,
    DeleteWarnCom
  ],
  imports:
  [
    SharedMod,
    BriefMaterialMod,
    RouterModule.forChild([]),
  ],
  providers:
  [
    BriefApiSrv,
    BriefClientSrv,
    BriefStateFty,
    StepStateSrv,
  ]
})
export class BriefMod {}

export * from './+';
export * from './brief-mdl';
export * from './api.srv';
export * from './client.srv';
export * from './form';
