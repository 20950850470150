import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ABriefFormStep } from './a.com';
import { BriefStep2Fields, BriefStep2Type } from '../brief-mdl';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map, tap, filter } from 'rxjs/operators';
import { mustMatch } from '../../project-wizard/edit';
import { NgForage } from 'ngforage';
import { ProjectWizardSrv } from '../../project-wizard/srv';
import { ProjectStoreSrv } from '../../project-wizard/store.srv';

// const PHONE_RE = /^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$/;
const PHONE_RE = /^[\+\s\d\(\)]+$/;
const { required, pattern, email } = Validators;
@Component
({
  selector: 'brief-form-step2',
  templateUrl: './step2.com.pug',
  styleUrls: ['./+.scss'],
})
export class BriefFormStep2Com extends ABriefFormStep<BriefStep2Type> implements OnInit
{
  contractTypeList: Observable<any>;
  functionList: Observable<any>;
  fieldKeys = BriefStep2Fields;
  ngOnInit()
  {
    const [fields, fieldKeys] = [this.fields, this.fieldKeys];
    this.form = new FormGroup
    ({
      [fieldKeys.projectName]: ( fields[fieldKeys.projectName] = new FormControl( '', required ) ),
      [fieldKeys.function]: ( fields[fieldKeys.function] = new FormControl( '', required ) ),
      [fieldKeys.contractType]: (fields[fieldKeys.contractType] = new FormControl('', required)),
      [fieldKeys.description]: (fields[fieldKeys.description] = new FormControl('', required, )),
    });
    this.contractTypeList = this.wireAutocompleteToService(fields[fieldKeys.contractType], this.apiSrv.contractTypes);
    this.functionList = this.wireAutocompleteToService(fields[fieldKeys.function], this.apiSrv.functions);
  }
}
