import { Component, forwardRef, AfterContentInit, ContentChild, ViewChild, ElementRef, Renderer2, Input, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatButton, MatFormField } from '@angular/material';
import { Platform } from '@angular/cdk/platform';

declare const safari: any;

@Component
({
  selector: '.file-upload',
  templateUrl: './file-upload.com.pug',
  styleUrls: ['./file-upload.com.scss'],
  providers:
  [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef( () => FileUploadCom ),
    }
  ]
})
export class FileUploadCom implements ControlValueAccessor, AfterContentInit
{
  // isSafari = /constructor/i.test( (window as any).HTMLElement)
  //   || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (safari as any).pushNotification);
  isSafari: boolean;
  constructor
  (
    private readonly renderer: Renderer2,
    readonly platform: Platform
  )
  {
    this.isSafari = platform.SAFARI;
  }

  @Input() maxSize = 10000000;
  rejectedFiles: File[] = [];
  files: File[] = [];
  writeValue( files: File[] ): void
  {
    this.files = files || [];
    this.toggleClass();
  }
  onChange( files: File[] ) {}
  registerOnChange( fn: ( files: File[] ) => void ): void { this.onChange = fn; }
  onTouched() {}
  registerOnTouched( fn: () => void ) { this.onTouched = fn; }

  isDisabled = false;
  setDisabledState( isDisabled: boolean ): void { this.isDisabled = isDisabled; }

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ContentChild(MatButton, { read: ElementRef } ) button: ElementRef<HTMLElement>;
  @ContentChild(MatFormField, { read: ElementRef } ) formField: ElementRef<HTMLElement>;
  ngAfterContentInit()
  {
    const buttonElem = this.button.nativeElement;
    this.renderer.setStyle( buttonElem, 'z-index', 2 );
    this.renderer.listen( buttonElem, 'click', () => this.fileInput.nativeElement.click() );
    this.toggleClass();
  }

  private toggleClass()
  {
    if ( this.files.length ) this.formField.nativeElement.classList.add('mat-form-field-should-float');
    else this.formField.nativeElement.classList.remove('mat-form-field-should-float');
  }
  allowedMimeTypes =
  [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'
  ];
  draggingOver = false;
  upload( event: DragEvent, input?: HTMLInputElement )
  {
    event.preventDefault();
    this.draggingOver = false;
    [ , this.files, this.rejectedFiles ] =
      [].slice.call( input ? input.files : event.dataTransfer.files )
        // tslint:disable-next-line:no-bitwise
        .filter( ( file: File ) => !!( !this.allowedMimeTypes.length || ~this.allowedMimeTypes.indexOf( file.type ) ) )
        .reduce( ( [ totalSize, acceptedFiles, rejectedFiles ], file: File ) =>
        {
          totalSize += file.size;
          const [ acceptedFile, rejectedFile ] = totalSize <= this.maxSize ? [ [ file ], [] ] : [ [], [ file ] ];
          return [ totalSize, [ ...acceptedFiles, ...acceptedFile ], [ ...rejectedFiles, ...rejectedFile ] ];
        }, [ 0, [], [] ] );
    this.onChange( this.files );
    this.toggleClass();
  }
  delete( index: number ): void
  {
    this.files.splice( index, 1 );
    this.onChange( this.files );
    this.toggleClass();
  }

  dragOver( event: DragEvent )
  {
    event.preventDefault();
    if ( this.draggingOver ) return;
    this.draggingOver = true;
  }
  dragLeave( event: DragEvent, priority = 1, from = '' )
  {
    event.preventDefault();
    if ( !this.draggingOver ) return;
    this.draggingOver = false;
  }
}
