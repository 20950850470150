import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthSrv } from '../main/auth';
import { Injectable } from '@angular/core';
import { tap, take, switchMap, catchError, switchMapTo } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AjaxInterceptor implements HttpInterceptor
{
  constructor(
    private readonly authSrv: AuthSrv,
    private readonly router: Router,
  ) {}

  intercept( req: HttpRequest<any>, next: HttpHandler )
  {
    let newReq = req.clone
    ({
      headers: req.headers.append( 'X-Requested-With', 'XMLHttpRequest' )
    });
    return this.authSrv.account.pipe
    (
      take(1),
      tap( account =>
      {
        if ( Object.keys(account).length )
          newReq = newReq.clone( { headers: newReq.headers.append( 'Authorization', `Bearer ${account.token}` ) } );
      } ),
      switchMap( _ => next.handle( newReq ) ),
      catchError( ( error: HttpErrorResponse ) =>
      {
        if ( error.status === 401 )
          return this.authSrv.logout().pipe
          (
            // tap( _ => this.router.navigate([ { outlets: { popup: [ 'auth' ] } } ]) ),
            tap( _ => this.router.navigateByUrl('/auth#') ),
            switchMapTo( throwError(error) )
          );
        return throwError(error);
      }),
    );
  }
}
