// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  devStuff()
  {
    const dateFormat = ( date?: Date ) =>
    {
      const fDate = date || new Date;
      const [ wd, m, d, y, t ] = fDate.toString().split(' ');
      return `${wd}, ${d}-${m}-${y} ${t}`;

    };
    (window as any).__fillInForm = function( prefix: string = '' ) {
      const project =
      {
        company_name: `${prefix} Some Company Name - ${dateFormat()}`,
        industry: { key: '100.100', value: 'Anlagenbau' },
        unit: '123',
        employee_count: null,
        revenue: { key: '500', value: '> 2 Mrd. EUR Umsatz'  },
        contact_name: 'Some Company Name',
        contact_function: 'Some Contact Function',
        contact_phone: null,
        contact_mobile: '+491234567',
        contact_email: 'some@email.com',
        contact_email_confirm: 'some@email.com',
        project_name: `${prefix} Project on ${dateFormat()}`,
        description: `Some Project Description.... \n
          Deadlights jack lad schooner scallywag dance the hempen jig carouser broadside cable strike colors.
          Bring a spring upon her cable holystone blow the man down spanker
          Shiver me timbers to go on account lookout wherry doubloon chase.
          Belay yo-ho-ho keelhaul squiffy black spot yardarm spyglass sheet transom heave to.`,
        contract_type: { key: '150', value: 'Umsetzungsberatungsprojekt' },
        start: new Date( +new Date - 6.307e+10 ).toISOString(),
        duration: '1',
        days_per_week: 1,
        location: 'Some Location',
        rate: 1,
        function: { key: '300.200', value: 'Controlling' },
        files: null,
        personal_requirements: [ true, false, true, false, false, false, false, false, false, ],
        special_requirements: [ false, false, true, false, false, true, false, false ],
        languages: [ true, false, true, false, false ],
        responsibility_employees: '43',
        responsibility_budget: '1234332',
        experience: '12',
        remark: null,
        selection_method: [ null, null, null ],
        created_at: (new Date).toISOString(),
        modified_at: (new Date).toISOString(),
        status: '',
        currentStep: null,
        key_competences: [ false, false, false, false, false, false, false, true, false, false, false, false,
          false, false, false, false, false, false, false, false, true, false, false, false, true, true, false,
          false, false, false, false, false, false, false, false, false, false, false, false ]
      };
      localStorage.setItem( 'myhead-new', JSON.stringify( project ) );
    };
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
