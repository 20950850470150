import { Injectable, AfterViewInit, OnDestroy, Component } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger, MatDialog } from '@angular/material';
import { Observable, zip, timer, Subscription, throwError, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map, tap, switchMapTo, finalize, catchError, take } from 'rxjs/operators';
import { BriefStateMdl, BriefStepType, BriefStepFields } from '../brief-mdl';
import { StepStateSrv } from './step-state.srv';
import { ActivatedRoute, Router } from '@angular/router';
import { extend } from '../../+';
import { BriefClientSrv } from '../client.srv';
import { BriefApiSrv } from '../api.srv';
import { AuthSrv } from '../../auth';

@Component({
  selector: 'brief-step-unsaved-warn',
  template: `
  <h1 mat-dialog-title i18n="@@title-unsaved-data">Unsaved data</h1>
  <div mat-dialog-content>
    <p i18n="@@text-want-to-save">Do you want to save?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial=focusNo [mat-dialog-close]="false" i18n="@@button-icon-no">
     <mat-icon svgIcon="fa:times"></mat-icon>NO
    </button>
    <button mat-flat-button color="primary" cdkFocusInitial=focusYes [mat-dialog-close]="true" i18n="@@button-yes">Ok</button>
  </div>
  `
})
export class UnsavedWarnCom {}

@Component({
  selector: 'brief-step-unsaved-warn',
  template: `
  <h1 mat-dialog-title i18n="@@title-delete-data">Delete data?</h1>
  <div mat-dialog-content>
    <p i18n="@@text-want-to-delete">Do you want to delete?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial=focusNo [mat-dialog-close]="false" i18n="@@button-icon-no">
     <mat-icon svgIcon="fa:times"></mat-icon>NO
    </button>
    <button mat-flat-button color="primary" cdkFocusInitial=focusYes [mat-dialog-close]="true" i18n="@@button-yes">Ok</button>
  </div>
  `
})
export class DeleteWarnCom { }


const VALUE_CHANGE_DEBOUNCE_TIME = 400;
@Injectable()
export abstract class ABriefFormStep<TBriefStepType extends BriefStepType> implements AfterViewInit, OnDestroy
{
  form: FormGroup;
  fieldKeys: BriefStepFields;
  fields: { [key in keyof TBriefStepType]?: AbstractControl } = {};
  submitted = false;
  submitError = false;
  protected valueChangeSub: Subscription;
  constructor
  (
    protected readonly stepStateSrv: StepStateSrv,
    protected readonly apiSrv: BriefApiSrv,
    protected readonly clientSrv: BriefClientSrv,
    protected readonly activeRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly dialog: MatDialog,
    protected readonly authSrv: AuthSrv
  ) { }

  protected fillDataCallback?( fillData: any ): void;

  ngAfterViewInit()
  {
    const fieldNames = Object.values(this.fieldKeys),
      tempStore$Values = fieldNames.map( controlName => this.stepStateSrv.get(controlName) );

    // expression changed bypass
    this.valueChangeSub = timer(1).pipe
    (
      switchMapTo( zip( ...tempStore$Values ) ),
      //                      TODO: runs on route change
      switchMap( tempStore => this.activeRoute.parent.data.pipe
      (
        map( ( { project: { data: formData, id } }: { project: BriefStateMdl } ) =>
        {
          this.submitted = !!id;
          if ( tempStore.some( v => !!v ) ) this.form.markAsDirty();
          return extend( extend( {}, formData ), tempStore.reduce( ( a, v, i ) =>  v !== undefined ? ( a[ fieldNames[i] ] = v, a) : a, {} ) );
        } )
      ) ),
      switchMap( fillData =>
      {
        Object.values(this.fieldKeys).forEach( fieldKey =>
        {
          const
            field = this.form.get(fieldKey) as FormControl,
            fillDataField = fillData[fieldKey];

          if ( field instanceof FormArray && fillDataField )
            fillDataField.forEach( (v: any, i: number) =>
            {
              const fieldCtrl = field.get(`${i}`);
              !fieldCtrl ? field.push( new FormControl( v ) ) : fieldCtrl.setValue( v );
            } );
          else field.patchValue( fillDataField || ((field instanceof FormArray) ? [] : undefined),
            { onlySelf: true, emitViewToModelChange: false, emitEvent: false } );
          // already submitted project?
          field[ fillData.id ? 'disable' : 'enable' ]();
          if ( this.fillDataCallback ) this.fillDataCallback( fillData );
        });
        return this.form.valueChanges.pipe
        (
          debounceTime(VALUE_CHANGE_DEBOUNCE_TIME),
          distinctUntilChanged(),
          switchMap( (formValue: any ) => zip( ...Object.entries(formValue)
            // tslint:disable-next-line: no-bitwise
            .map( ([ key, value ]) => this.stepStateSrv.set(key, value) ) ) )
        );
      })
    ).subscribe();
  }
  ngOnDestroy()
  {
    this.stepStateSrv.clear();
    return this.valueChangeSub
      // allow debounce to pass before destruction
      && timer(VALUE_CHANGE_DEBOUNCE_TIME).pipe( tap( _ => this.valueChangeSub.unsubscribe() ) ).subscribe();
  }

  // Autocomplete helpers
  wireAutocompleteToService( control: AbstractControl, serviceList: Observable<any> ): Observable<any>
  {
    return control.valueChanges.pipe
    (
      debounceTime( 400 ),
      distinctUntilChanged(),
      startWith( null ),
      switchMap( searchValue => serviceList.pipe( map( list => ({ searchValue, list }) ) ) ),
      map( ({ searchValue, list }) =>
      {
        // tslint:disable-next-line:no-bitwise
        if ( ~[null, undefined].indexOf(searchValue) || typeof searchValue === 'object' ) return list;
        return list.map( ({ children, ...other }) =>
        ({
          // tslint:disable-next-line:no-bitwise
          children: children.filter( ({ value }) => !!~value.toLowerCase().indexOf( searchValue.toLowerCase() ) ),
          ...other
        }) );
      } )
    );
  }
  autoDisplayValue( v: any )
  {
    if ( !v ) return v;
    if ( typeof v === 'object' ) return v.value;
    return v;
  }
  autoClosed( control: AbstractControl ) { if ( typeof control.value !== 'object' ) control.setValue(''); }
  openAutoPanel( event: Event, trigger: MatAutocompleteTrigger )
  {
    if ( trigger.autocompleteDisabled ) return false;
    event.stopPropagation();
    if ( trigger.panelOpen ) return trigger.closePanel();
    return trigger.openPanel();
  }

  unsavedWarnTpl() { return this.dialog.open(UnsavedWarnCom).afterClosed(); }
  deleteWarnTpl() { return this.dialog.open(DeleteWarnCom).afterClosed(); }

  prev()
  {
    const url = Array.from(this.activeRoute.snapshot.url).pop().path,
      nextStep = url.replace(/\d+$/, '' + ( +url.match(/\d+$/) - 1 ) );
    return this.router.navigate( ['..', nextStep], { relativeTo: this.activeRoute } );
  }
  cancel()
  {
    // TODO: close method for submitted briefings
    if ( this.submitted ) return this.router.navigate( ['/dashboard'] );
    this.clientSrv.get().pipe
    (
      switchMap(brief => brief.progress ? this.deleteWarnTpl() : of(true) ),
      switchMap<any, any>( ok => {
          return !ok
          ? throwError(Error('Canceled'))
          : this.clientSrv.delete();
        }
      ),
      switchMap(_ => this.authSrv.account.pipe(take(1))),
      switchMap(account => {
        return (this.form.markAsPristine(), this.router.navigate([Object.keys(account).length ? '/dashboard' : '/briefing ']));
      }), catchError(e => of(e))
    ).subscribe();
  }
  private dataStatus()
  {
    return Object.entries( this.form.controls )
      .map( ([key, control]) => [ key, control.dirty || ( Array.isArray(control.value) ? control.value.length : control.value ) ? control.valid : undefined ] )
      .reduce( (a, [key, value]: [string, any]) => ( a[key] = value, a ), {} );
  }
  save()
  {
    this.clientSrv.saveData( this.form.value, this.dataStatus() ).pipe
    (
      switchMap( _ =>
      {
        this.form.markAsPristine();
        return this.router.navigate( ['/dashboard'] );
      } )
    ).subscribe();
  }
  protected formInvalid()
  {
    if ( this.submitted ) return this.form.markAsPending();
    // check validation
    Object.keys(this.form.controls).forEach( field =>
    {
      const control = this.form.get(field);
      control.markAsDirty({ onlySelf: true });
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    });
    this.form.updateValueAndValidity();
    return this.form.invalid;
  }
  next()
  {
    if ( this.formInvalid() && !this.form.disabled ) return;
    ( this.submitted ? of({}) : this.clientSrv.saveData( this.form.value, this.dataStatus() ) ).pipe
    (
      switchMap( _ =>
      {
        const url = this.activeRoute.snapshot.url.pop().path,
          nextStep = url.replace(/\d+$/, '' + ( +url.match(/\d+$/) + 1 ) );
        this.form.markAsPristine();
        return this.router.navigate( ['..', nextStep], { relativeTo: this.activeRoute } );
      } )
    ).subscribe();
  }
  submitting = false;
  protected preSubmitData( data: any ) { return data; }
  protected preSubmitCheck() { return true; }
  submit()
  {
    if ( this.formInvalid() || !this.preSubmitCheck() ) return false;
    this.submitting = true;
    this.clientSrv.saveData( this.form.value, this.dataStatus() ).pipe
    (
      switchMap( _ => this.clientSrv.get() ),
      switchMap( ({ data }) => this.apiSrv.submit( this.preSubmitData(data) ) ),
      finalize( () => ( this.form.markAsPristine(), ( this.submitting = false ) ) ),
      catchError( e => ( ( this.submitError = true ), throwError(e)) ),
      switchMap( _ => this.clientSrv.delete() ),
      switchMap( _ => this.router.navigate( ['/dashboard'] ) )
    ).subscribe();
  }
}
