import { Injectable } from '@angular/core';
import { Observable, of, from, zip } from 'rxjs';
import { CLIENT_STORAGE_KEY, CLIENT_STORAGE_FILE_KEY_SLUG } from './+';
import { BriefMdl, BriefStateMdl, DataStatus } from './brief-mdl';
import { extend } from '../+';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { ABriefSrv } from './a.srv';
import { NgForage } from 'ngforage';
import { BriefStateFty } from './brief-state.fty';

interface CustomFile
{
  name: string;
  type: string;
  size: number;
  lastModified: any;
  data: ArrayBuffer;
}
@Injectable()
export class BriefClientSrv extends ABriefSrv
{
  constructor
  (
    protected readonly briefStateFty: BriefStateFty,
    private readonly storage: NgForage,
  ) { super(briefStateFty); }
  /**
   * @deprecated remove in future versions
   */
  private removeLegacyData()
  {
    const oldData = JSON.parse( localStorage.getItem( CLIENT_STORAGE_KEY ) );
    if ( oldData && !oldData.data ) localStorage.removeItem( CLIENT_STORAGE_KEY );
  }
  /**
   * @deprecated remove in future versions
   */
  private checkForLegacyData(): Observable<any>
  {
    const oldData = JSON.parse( localStorage.getItem( CLIENT_STORAGE_KEY ) );
    if ( oldData && !oldData.data ) return of({ data: this.toInternal(oldData), ...oldData });
    return this.getData();
  }
  private getData(): Observable<any>
  {
    return from(this.storage.getItem( CLIENT_STORAGE_KEY ));
  }

  list(): Observable<BriefStateMdl[]>
  {
    return this.checkForLegacyData().pipe
    (
      switchMap( brief => brief && brief.data
        ? this.getFilesBuffers( brief.data.files ).pipe( map( files => ( brief.data.files = files, brief ) ) )
        : of(undefined) ),
      map( brief => [brief].filter( b => !!b ).map( b => this.briefStateFty.create( extend( new BriefMdl, b ) ) ) )
    );
  }
  get( id: number = 0 ): Observable<BriefStateMdl>
  {
    return this.checkForLegacyData().pipe
    (
      // get files from buffers' storage
      switchMap( brief => brief && brief.data
        ? this.getFilesBuffers( brief.data.files ).pipe( map( files => ( brief.data.files = files, brief ) ) )
        : of({ data: {} }) ),
      map( brief => this.briefStateFty.create( extend( new BriefMdl, this.toInternal(brief.data) ) ) )
    );
  }
  saveData( newBriefData: Partial<BriefMdl>, dataStatus?: DataStatus<BriefMdl> )
  {
    // first do a get from saved files
    return this.get().pipe
    (
      // check for new files
      switchMap( brief =>
      (
        newBriefData.files && Array.isArray(newBriefData.files)
          // got upload
          ? newBriefData.files.every( file => file instanceof File )
            // save files to storage
            ? this.saveFiles( newBriefData.files )
            // no upload, files from already saved to storage
            : of(newBriefData.files)
          // just retrieve old saved files
          : of( brief.data.files )
      ).pipe( map( newFiles => ({ brief, newFiles }) ) ) ),
      // then merge with new data
      switchMap( ({ brief, newFiles }) =>
      {
        // test if saving files failed, something different than array, like an error or something
        newBriefData.files = Array.isArray(newFiles) ? newFiles.map( ({ data, ...rest }) => rest ) : [];
        brief.patchData( newBriefData, dataStatus );
        this.removeLegacyData();
        return this.storage.setItem( CLIENT_STORAGE_KEY, brief.toObject() );
      } )
    );
  }

  private storageFileKey( file: File ) { return `${CLIENT_STORAGE_KEY}${CLIENT_STORAGE_FILE_KEY_SLUG}${file.name}`; }
  // save files to storage a bit different because Safari...
  saveFiles( files: File[] ): Observable<CustomFile[]>
  {
    return from( this.storage.keys() ).pipe
    (
      // tslint:disable-next-line: no-bitwise
      map( allKeys => allKeys.filter( key => ~key.indexOf( CLIENT_STORAGE_FILE_KEY_SLUG ) ) ),
      switchMap( fileKeys => zip( of(null), ...fileKeys.map( fileKey => from( this.storage.removeItem(fileKey) ) ) ) ),
      switchMap( _ => zip( of(null),
        ...files.map( file => from(file.arrayBuffer()).pipe
        (
          switchMap( fileAB => this.storage.setItem( this.storageFileKey(file), fileAB ) ),
          map( data => ({ name: file.name, type: file.type, size: file.size, lastModified: file.lastModified, data }) )
        ) )
      ) ),
      map( ([ _, ...storedFiles ]) => storedFiles ),
      catchError( e => of(e) ),
    );
  }
  private getFilesBuffers( files: Array<{name: string}> ): Observable<CustomFile[]>
  {
    return from( this.storage.keys() ).pipe
    (
      // tslint:disable-next-line: no-bitwise
      map( allKeys => allKeys.filter( key => ~key.indexOf( CLIENT_STORAGE_FILE_KEY_SLUG ) ) ),
      switchMap( fileKeys => zip( of(null), ...fileKeys
        .map( fileKey =>
        {
          const [ , fileName ] = fileKey.split(CLIENT_STORAGE_FILE_KEY_SLUG),
            file = (files || []).find( ({ name }) => name === fileName );
          return file
            ? from( this.storage.getItem(fileKey) ).pipe( map( data => ({ data, ...file }) ) )
            // remove if not found in main storage object files array
            : from( this.storage.removeItem(fileKey) );
        } ) ) ),
      map( storedFiles => storedFiles.filter( file => !!file ) ),
    );
  }

  delete()
  {
    return from( this.storage.keys() ).pipe
    (
      // tslint:disable-next-line: no-bitwise
      map( allKeys => allKeys.filter( key => ~key.indexOf( CLIENT_STORAGE_FILE_KEY_SLUG ) ) ),
      switchMap( fileKeys => zip( of(null), ...fileKeys.map( fileKey => from( this.storage.removeItem(fileKey) ) ) ) ),
      switchMap( _ => from( this.storage.removeItem( CLIENT_STORAGE_KEY ) ) )
    );
  }
}
