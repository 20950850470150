import { Component, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { CallFormCom } from '../call-form.com';
import { ABriefFormStep } from './form/a.com';
import { BriefStepType } from './brief-mdl';
import { map, tap, takeWhile } from 'rxjs/operators';

@Component
({
  selector: 'brief-form',
  templateUrl: './form.com.pug',
  styleUrls: ['./form.com.scss'],
})
export class BriefFormCom
{
  showLoading = false;
  @HostBinding('class.x-small') private xSmallClass = false;
  @HostBinding('class.small') private smallClass = false;
  @HostBinding('class.medium') private mediumClass = false;
  @HostBinding('class.large') private largeClass = false;
  @HostBinding('class.x-large') private xLargeClass = false;
  private progressSubject = new BehaviorSubject<[number, any[]]>([0, []]);
  progress$ = this.progressSubject.pipe( map( ([progress]) => progress ));
  constructor
  (
    private readonly activeRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    readonly brkPntObs: BreakpointObserver
  )
  {
    this.activeRoute.data.subscribe( ( { project: { steps, progress } } ) => this.progressSubject.next([progress, steps]) );
    Breakpoints.XSmall = '(max-width: 304px)';
    Breakpoints.Small = '(min-width: 305px) and (max-width: 768px)';
    Breakpoints.Medium = '(min-width: 769px) and (max-width: 992px)';
    Breakpoints.Large = '(min-width: 993px) and (max-width: 1200px)';
    Breakpoints.XLarge = '(min-width: 1201px)';
    brkPntObs.observe(Breakpoints.XSmall).subscribe(r => this.xSmallClass = r.matches);
    brkPntObs.observe(Breakpoints.Small).subscribe(r => this.smallClass = r.matches);
    brkPntObs.observe(Breakpoints.Medium).subscribe(r => this.mediumClass = r.matches);
    brkPntObs.observe(Breakpoints.Large).subscribe(r => this.largeClass = r.matches);
    brkPntObs.observe(Breakpoints.XLarge).subscribe(r => this.xLargeClass = r.matches);
  }
  @ViewChild('formTop') top: ElementRef<any>;
  allSteps = Array(6).fill(undefined).map( ( _, i) => i );
  currentChild: ABriefFormStep<BriefStepType>;
  // TODO: TBI
  passedStepIndex$ = this.progressSubject.pipe( map( ([progress, steps]) => progress * steps.length ) );
  currentIndex: number;
  currentFormComponent: any;
  stepActivate( componentInstance: ABriefFormStep<BriefStepType> )
  {
    this.currentChild = componentInstance;
    this.currentIndex = this.activeRoute.routeConfig.children
      .filter( ({ component }) => !!component )
      .findIndex( ({ component }) => component === this.currentChild.constructor );
    this.currentFormComponent = componentInstance;

    timer(100).pipe( tap( () => this.top.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest' }) ) ).subscribe();
  }
  openModalCallback() {
    this.dialog.open(CallFormCom).afterClosed().pipe(takeWhile(data => !!data))
      .subscribe();
  }
}
