import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Driver, DEFAULT_CONFIG } from 'ngforage';
import { AppRoutingMod } from './routing.mod';
import { AppCom } from './app.com';
import { MainMod } from './main';


@NgModule({
  declarations:
  [
    AppCom
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MainMod,
    AppRoutingMod,
  ],
  providers:
  [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: DEFAULT_CONFIG,
      useValue: { name: 'myHead' }
    }
  ],
  bootstrap: [ AppCom ]
})
export class AppMod {}
