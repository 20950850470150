<div class="registration-box">
<div class="content-container">
<h2 i18n="@@title-auth-register"> Register </h2>
<p i18n="@@text-auth-register-description"> Please register here </p>
</div>
<div class="form-container">
<form [formGroup]="form" (ngSubmit)="register()">
<div class="form-group flex-col row-inner"><mat-form-field class="col" appearance="outline"><mat-label i18n="@@label-email">E-Mail</mat-label>
<input matInput [formControl]="email"><mat-error class="field-error" [control]="email"></mat-error></mat-form-field></div>
<div class="form-group flex-col row-inner"><mat-form-field class="col" appearance="outline"><mat-label i18n="@@label-confirm-email" type="password">Confirm E-Mail</mat-label>
<input matInput [formControl]="confirmEmail"><mat-error class="field-error" [control]="confirmEmail"></mat-error></mat-form-field></div>
<div class="form-group flex-col row-inner"><mat-form-field class="col" appearance="outline"><mat-label i18n="@@label-password">Password</mat-label><input matInput [type]=" hidePass ? 'password' : 'text' " [formControl]="password"><mat-icon matSuffix (click)=" hidePass = !hidePass">{{ hidePass ? 'visibility_off' : 'visibility' }}</mat-icon><mat-error class="field-error" [control]="password"></mat-error></mat-form-field></div>
<div class="form-group flex-col row-inner"><mat-form-field class="col" appearance="outline"><mat-label i18n="@@label-confirm-password">Confirm Password</mat-label><input matInput [type]=" hideConfirmPass ? 'password' : 'text' " [formControl]="confirmPassword"><mat-icon matSuffix (click)=" hideConfirmPass = !hideConfirmPass">{{ hideConfirmPass ? 'visibility_off' : 'visibility' }}</mat-icon><mat-error class="field-error" [control]="confirmPassword"></mat-error></mat-form-field></div>
<div class="form-group flex-col row-inner">
<div class="checkbox-field col xs-12 m-12">
<p i18n="@@text-register-agree">In order to be able to submit the registration, please agree to our terms and conditions.
The terms and conditions can be found <a href="https://myhead.com/agb" target="_blank">here</a>.</p><mat-checkbox formControlName="agree" i18n="@@label-agree-terms">I agree to the <a href="https://myhead.com/agb" target="_blank">Terms and Conditions</a></mat-checkbox><mat-error class="field-error" *ngIf="agree.dirty" [control]="agree"></mat-error></div>
</div>
<div class="button-group left">
<button mat-flat-button color="primary" type="submit" [disabled]="registering" i18n="@@button-register">Register</button>
</div>
</form>
</div>
<div class="alert alert-danger messages" *ngIf="(serverRegistrationErrors|keyvalue).length">
<ol style="list-style-type: circle;">
<li *ngFor=" let message of serverRegistrationErrors | keyvalue "> {{message.value}} </li>
</ol>
</div>
</div>