import { NgModule } from '@angular/core';
import {
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatIconRegistry,
  MatCheckboxModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatCardModule,
} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { faSave, faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faTimes,
  faFile,
  faPhone,
  faExclamationCircle,
  faFlagCheckered,
  faPaperPlane,
  faTrashAlt,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { MainRoutingMod } from './routing.mod';
import { AuthCom } from './auth.com';
import { LoginCom, LoginRegisterCom, ResetCom, ChangeCom, LoginPopupCom, LoginNewCom } from './auth/';
import { RegisterCom } from './auth/';
import { MainCom } from './com';
import { AjaxInterceptor } from '../+/http';
import { SharedMod } from '../shared';
import { ProjectSrv } from './project.srv';
import { ProjectStoreSrv } from './project-wizard/store.srv';
import { ProjectWizardSrv } from './project-wizard/srv';
import { CallFormCom } from './call-form.com';
import { BriefMod } from './brief';
import { DashboardNewCom } from './dashboard-new.com';

import { registerLocaleData } from '@angular/common';
import DE_LOCALE from '@angular/common/locales/de';

@NgModule
({
  declarations:
  [
    MainCom,
    AuthCom,
    LoginRegisterCom,
    LoginPopupCom,
    LoginNewCom,
    LoginCom,
    RegisterCom,
    ResetCom,
    ChangeCom,
    CallFormCom,
    DashboardNewCom,
  ],
  entryComponents: [ CallFormCom ],
  imports:
  [
    SharedMod,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    BriefMod,
    MainRoutingMod,
  ],
  providers:
  [
    ProjectSrv,
    ProjectWizardSrv,
    ProjectStoreSrv,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AjaxInterceptor,
      multi: true
    }
  ]
})
export class MainMod
{
  constructor
  (
    readonly iconReg: MatIconRegistry,
    readonly sanitizer: DomSanitizer,
  )
  {
    const svg = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
        <defs>
          ${[
            faCheck,
            faEdit,
            faExclamationCircle,
            faEye,
            faTimes,
            faFlagCheckered,
            faPaperPlane,
            faSave,
            faFile,
            faPhone,
            faTrashAlt,
            faUpload,
          ].map( ({ icon: [width, height, _, __, path], iconName }) =>
            ` <svg viewBox="0 0 ${width} ${height}" id="${iconName}"> <path d="${path}" /> </svg> `).join('')}
        </defs>
      </svg>`;
    iconReg.addSvgIconSetLiteralInNamespace( 'fa', sanitizer.bypassSecurityTrustHtml(svg) );

    // adjust DE short date because reasons
    (DE_LOCALE[10] as string[]).shift();
    DE_LOCALE[10] = ['dd.MM.yyyy', ...(DE_LOCALE[10] as string[])];
    registerLocaleData(DE_LOCALE);
  }
}
