import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ABriefFormStep } from './a.com';
import { BriefStep1Fields, BriefStep1Type } from '../brief-mdl';
import { extend } from '../../+';
import { switchMap, tap } from 'rxjs/operators';
import { timer } from 'rxjs';

const { required, pattern, email } = Validators;
const PHONE_RE = /^[\+\s\d\(\)]+$/;
@Component
({
  selector: 'brief-form-step1',
  templateUrl: './step1.com.pug',
  styleUrls: ['./+.scss', './step1.com.scss'],
})
export class BriefFormStep1Com extends ABriefFormStep<BriefStep1Type> implements OnInit, AfterViewInit
{
  fieldKeys = BriefStep1Fields;
  ngOnInit()
  {
    const [fields, fieldKeys] = [this.fields, this.fieldKeys];
    this.form = new FormGroup
    ({
      [fieldKeys.companyName]: ( fields[fieldKeys.companyName] = new FormControl( '', required ) ),
      [fieldKeys.contactName]: ( fields[fieldKeys.contactName] = new FormControl( '', required ) ),
      [fieldKeys.type]: (fields[fieldKeys.type] = new FormControl('', required)),
      [fieldKeys.files]: (fields[fieldKeys.files] = new FormControl('')),
      [fieldKeys.contactFunction]: ( fields[fieldKeys.contactFunction] = new FormControl( '', required ) ),
      [fieldKeys.contactPhone]: (fields[fieldKeys.contactPhone] = new FormControl('', [required, pattern(PHONE_RE)] ) ),
      [fieldKeys.contactEmail]: (fields[fieldKeys.contactEmail] = new FormControl('', [required, email] ) ),
    });
  }
  protected preSubmitData( data: any ) { return extend( extend( {}, data ), { step: 1 } ); }
  protected preSubmitCheck()
  {
    this.fields.files.setValidators(required);
    this.fields.files.updateValueAndValidity();
    if ( this.form.invalid ) return this.fields.files.clearValidators(), false;
    return this.fields.files.clearValidators(), true;
  }
}
