import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component
({
  selector: '.field-error',
  template: `

    <ng-container *ngIf="control && control.invalid" i18n="@@input-errors">
    {
      (control.errors|keyvalue)[0].key, select,
        required {This field is required}
        email {This field must be a valid email format}
        pattern {This field must be correctly formatted}
        emailMustMatch {Emails must match}
        passwordMustMatch {Passwords must match}
        atLeastOneChecked {Please select at least one}
    }
    </ng-container>`
})
export class FieldErrorCom
{
  @Input() control: AbstractControl;
}
