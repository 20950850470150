import { Component, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { AuthSrv } from './auth';
import { MatDialog } from '@angular/material';
import { CallFormCom } from './call-form.com';
import { takeWhile, tap } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { timer } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component
({
  selector: 'main-mod',
  templateUrl: './com.pug',
})
export class MainCom implements AfterViewInit
{
  isLoggedIn = false;
  constructor
  (
    private readonly authSrv: AuthSrv,
    private readonly dialog: MatDialog,
    readonly router: Router,
    readonly brkPntObs: BreakpointObserver,
  ) {
    authSrv.account.subscribe( a => this.isLoggedIn = !!Object.keys(a).length );
    router.events.subscribe( e =>
    {
      if ( e instanceof NavigationStart ) this.showNavigation = false;
    });
    brkPntObs.observe( '(min-width: 1400px)' ).subscribe( r => this.disableMenuScroll = !r.matches );
  }

  disableMenuScroll = false;
  menuHeight: number;
  menuOriginalHeight = 200; // TODO: read dynamically based on breakpoint observers
  @ViewChild('stickyMenu') menuElement: ElementRef;
  ngAfterViewInit()
  {
    timer(1).pipe( tap( () => this.menuHeight = this.menuElement.nativeElement.offsetHeight ) ).subscribe();
  }
  @HostListener('window:scroll', ['$event'])
  handleScroll( )
  {
    // TODO: hack to stop resizing of header when dialog is open
    if ( window && window.document )
    {
      const [ html ] = Array.from(window.document.getElementsByTagName('html'));
      if ( html && html.classList.contains('cdk-global-scrollblock') ) return false;
    }
    const windowScroll = window.pageYOffset;
    this.menuHeight = windowScroll > 100 ? 100 : this.menuOriginalHeight - windowScroll;
  }

  showNavigation = false;

  openModalCallback()
  {
    this.dialog.open(CallFormCom).afterClosed().pipe( takeWhile( data => !!data ) )
      .subscribe();
  }
  logout()
  {
    this.authSrv.logout().subscribe( _ => (window.location.replace('https://myhead.com/') ) );
  }
}
