import { NgModule, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  Resolve,
} from '@angular/router';
import { AuthCom } from './auth.com';
import { MainCom } from './com';
import { AuthSrv, LoginRegisterCom, ResetCom, ChangeCom, LoginPopupCom } from './auth';
import { Observable, of, zip } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ProjectSrv } from './project.srv';
import { DashboardNewCom } from './dashboard-new.com';
import { BriefApiSrv } from './brief';
import { BriefClientSrv } from './brief/client.srv';
import { BRIEF_ROUTES, BriefResolver, BriefStepDeactivate, CurrentStepGuard } from './brief/routing';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
  constructor
  (
    private auth: AuthSrv,
    private router: Router,
    private location: Location,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
  {
    const url: string = state.url;
    return this.checkLogin(url, route.data.authUrl);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
  {
    return this.canActivate(route, state);
  }

  private _canLoadUrl: string = null;
  canLoad(route: Route): Observable<boolean>
  {
    const url = `${this.location.path()}`;
    return this.checkLogin(this._canLoadUrl || url, route.data.authUrl);
  }

  checkLogin(url: string, authUrl: any[]): Observable<boolean>
  {
    // console.log( 'redirect url:', url );
    return this.auth.account.pipe
    (
      take(1),
      map( ({ id, ...rest }) =>
      {
        if ( id ) return true;
        // store redirectUrl
        this.auth.redirectUrl = url;
        this.router.navigate(authUrl);
        return false;
      })
    );
  }
}

@Injectable()
export class ProjectsResolver implements Resolve<Observable<any>>
{
  constructor
  (
    private readonly briefApiSrv: BriefApiSrv,
    private readonly briefClientSrv: BriefClientSrv,
  ) {}
  resolve()
  {
    return zip
    (
      this.briefApiSrv.list(),
      this.briefClientSrv.list()
    ).pipe
    (
      map( ( [ apiData, clientData ] ) => [ ...clientData, ...apiData ] )
    );
  }
}

@Injectable()
export class ProjectResolver implements Resolve<Observable<any>>
{
  constructor( private readonly projectSrv: ProjectSrv ) {}
  resolve( route: ActivatedRouteSnapshot )
  {
    let id: number;
    return ( id = +route.paramMap.get('id') )
      ? this.projectSrv.get(id)
      // TODO: from service
      : of( JSON.parse(localStorage.getItem( 'myhead-new' ) ) );
  }
}

const routes: Routes =
[
  {
    path: 'auth',
    outlet: 'popup',
    component: LoginPopupCom,
  },
  {
    path: '',
    component: MainCom,
    children:
    [
      {
        path: '',
        redirectTo: 'briefing',
        pathMatch: 'full'
      },
      {
        path: 'change-password',
        canActivate: [ AuthGuard ],
        canActivateChild: [ AuthGuard ],
        component: AuthCom,
        children:
        [
          { path: '', component: ChangeCom },
        ]
      },
      {
        path: 'dashboard',
        component: DashboardNewCom,
        data: { authUrl: ['auth'] },
        canActivate: [ AuthGuard ],
        canActivateChild: [ AuthGuard ],
        resolve: { projects: ProjectsResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'auth',
        component: AuthCom,
        children:
        [
          { path: '', redirectTo: 'login', pathMatch: 'full' },
          { path: 'login', component: LoginRegisterCom },
          { path: 'reset-pass/:token/:uid', component: ResetCom }
        ]
      },
      {
        path: 'project',
        loadChildren: './project-wizard#ProjectWizardMod'
      },
      ...BRIEF_ROUTES
    ]
  },
];

@NgModule
({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
  providers: [ AuthGuard, ProjectResolver, ProjectsResolver, BriefResolver, BriefStepDeactivate, CurrentStepGuard ],
})
export class MainRoutingMod { }
