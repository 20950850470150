import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, tap, finalize } from 'rxjs/operators';
import { BehaviorSubject, timer, Observable } from 'rxjs';

export class AuthError extends Error
{
  constructor( message: string, public data: any )
  {
    super(message);
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}
const LOCAL_STORAGE_KEY = 'myhead-auth';
@Injectable({ providedIn: 'root' })
export class AuthSrv
{
  redirectUrl: string = null;
  constructor
  (
    protected readonly  http: HttpClient,
    private readonly router: Router,
  ) {}

  private _account = new BehaviorSubject<any>( JSON.parse( localStorage.getItem( LOCAL_STORAGE_KEY ) ) || {} );
  get account() { return this._account.asObservable(); }

  login( email: string, password: string, remember: any = 0 )
  {
    return this.http.post('/api/login', { email, password, remember } ).pipe
    (
      map( ( response: { code: number, data: any, messages: string[] } ) =>
      {
        if ( response.code && response.code <= 0 ) throw new AuthError( '' + 401, response.messages );
        localStorage.setItem( LOCAL_STORAGE_KEY, JSON.stringify( response.data ) );
        this._account.next( response.data );
        timer(1).pipe( tap( () =>
        {
          this.router.navigateByUrl( this.redirectUrl || '/' );
          this.redirectUrl = null;
        } ) ).subscribe();
      })
    );
  }
  logout()
  {
    return this.http.get('/api/logout').pipe
    (
      finalize( () => ( localStorage.removeItem( LOCAL_STORAGE_KEY ), this._account.next( {} ) ) )
    );
  }

  register( email: string, password: string, agree: boolean )
  {
    return this.http.post('/api/register', { email, email_check: email, password, password_check: password, agb_check: agree } ).pipe
    (
      map( ( response: { code: number, data: any, messages: string[] } ) =>
      {
        if ( response.code && response.code <= 0 ) throw new AuthError( '' + 400, response.messages );
        return response;
      })
    );
  }

  requestPassReset( username: string )
  {
    return this.http.post('/api/password-reset-request', { username } ).pipe
    (
      map( ( response: { code: number, data: any, messages: string[] } ) =>
      {
        if ( response.code && response.code <= 0 ) throw new AuthError( '' + 400, response.messages );
        return response;
      })
    );
  }

  passReset( password: string, token: string, uid: string )
  {
    return this.http.post('/api/password-reset', { token, uid, password, password_confirmation: password } ).pipe
    (
      map( ( response: { code: number, data: any, messages: string[] } ) =>
      {
        if ( response.code && response.code <= 0 ) throw new AuthError( '' + 400, response.messages );
        return response;
      })
    );
  }

  changePass( oldPass: string, password: string )
  {
    return this.http.post('/api/change-password', { old_password: oldPass, password, password_confirmation: password }).pipe
    (
      map( ( response: { code: number, data: any, messages: string[] } ) =>
      {
        if ( response.code && response.code <= 0 ) throw new AuthError( '' + 400, response.messages );
        return response;
      })
    );
  }

}
