import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { AuthSrv } from '../../auth';
import { Observable, zip } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ABriefFormStep } from './a.com';
import { BriefStep6Type, BriefStep6Fields } from '../brief-mdl';
import { StepStateSrv } from './step-state.srv';
import { BriefClientSrv } from '../client.srv';
import { BriefApiSrv } from '../api.srv';

const { required } = Validators;
@Component
  ({
    selector: 'brief-form-step6',
    templateUrl: './step6.com.pug',
    styleUrls: ['./+.scss', './step6.com.scss'],
  })
export class BriefFormStep6Com extends ABriefFormStep<BriefStep6Type> implements OnInit {
  public loggedIn$: Observable<any>;
  constructor
    (
      protected readonly stepStateSrv: StepStateSrv,
      protected readonly apiSrv: BriefApiSrv,
      protected readonly clientSrv: BriefClientSrv,
      protected readonly activeRoute: ActivatedRoute,
      protected readonly router: Router,
      protected readonly dialog: MatDialog,
      protected readonly authSrv: AuthSrv,
  ) {
    super(stepStateSrv, apiSrv, clientSrv, activeRoute, router, dialog, authSrv);
    this.loggedIn$ = this.authSrv.account.pipe(map(a => !!Object.keys(a).length));
  }

  selectionMethodList: any[];
  form: FormGroup;
  fieldKeys = BriefStep6Fields;
  ngOnInit()
  {
    const [fields, fieldKeys] = [this.fields, this.fieldKeys];
    this.form = new FormGroup
    ({
      [fieldKeys.employeeResponsibilityCount]: (fields[fieldKeys.employeeResponsibilityCount] = new FormControl('')),
      [fieldKeys.budget]: (fields[fieldKeys.budget] = new FormControl('')),
      [fieldKeys.experience]: (fields[fieldKeys.experience] = new FormControl('')),
      [fieldKeys.remark]: (fields[fieldKeys.remark] = new FormControl('')),
      [fieldKeys.anon]: (fields[fieldKeys.anon] = new FormControl('')),
      [fieldKeys.selectionMethod]: (fields[fieldKeys.selectionMethod] = new FormArray([])),
    });
  }
  protected fillDataCallback( data: any )
  {
    const selectionMethodField = this.fields.selectionMethod as FormArray;
    zip
    (
      this.apiSrv.selectionMethods,
    ).pipe
    (
      tap( ([ methodList ]) =>
      {
        this.selectionMethodList = methodList;
        if (selectionMethodField.controls.length < this.selectionMethodList.length)
          Array(methodList.length - selectionMethodField.controls.length).fill(undefined)
            .forEach( (_, i) => selectionMethodField.push(new FormControl( data.selectionMethod && data.selectionMethod[i] || false ) ) );
      })
    ).subscribe();
  }
}
