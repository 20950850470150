import { Component } from '@angular/core';
import { AuthSrv, AuthError } from './srv';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Validators as CustomValidators } from '../+';
import { ActivatedRoute, Router } from '@angular/router';
const { required } = Validators;
const { mustMatch } = CustomValidators;

@Component
({
  selector: 'main-auth-change-pass',
  templateUrl: './change-pass.com.pug',
  styleUrls: ['./+.scss'],
})
export class ChangeCom
{
  hideResetOldPass = true;
  hideResetPass = true;
  hideResetPassConfirm = true;
  resetting = false;
  serverErrors: string[] = [];
  form: FormGroup;
  oldPass: FormControl;
  pass: FormControl;
  passConfirm: FormControl;
  constructor
  (
    private readonly authSrv: AuthSrv,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  )
  {
    this.form = new FormGroup
    ({
      oldPass: ( this.oldPass = new FormControl( '', [ required ] ) ),
      pass: ( this.pass = new FormControl( '', [ required ] ) ),
      passConfirm: ( this.passConfirm = new FormControl( '', [ required, mustMatch('pass', 'password') ] ) ),
    });
  }
  reset()
  {
    Object.keys(this.form.controls).forEach( control => this.form.get(control).markAsTouched() );
    if ( this.form.invalid ) return;
    this.resetting = true;
    this.serverErrors = [];
    this.authSrv.changePass( this.oldPass.value, this.pass.value ).pipe
    (
      finalize( () => { this.resetting = false; } )
    )
    .subscribe( _ => this.router.navigateByUrl('/dashboard'), e => this.serverErrors = e instanceof AuthError ? e.data : [] );
  }
}
