import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Validators as CustomValidators } from '../+';
import { AuthSrv, AuthError } from './srv';
import { finalize } from 'rxjs/operators';

const
  { required, requiredTrue, email } = Validators,
  { mustMatch } = CustomValidators;

  @Component
({
  selector: 'main-auth-register',
  templateUrl: './register.com.pug',
  styleUrls: ['./+.scss', './register.com.scss'],
})
export class RegisterCom
{
  serverRegistrationErrors: string[] = [];
  registering = false;
  hideConfirmPass = true;
  hidePass = true;

  form: FormGroup;
  email: FormControl;
  confirmEmail: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  agree: FormControl;
  constructor
  (
    private readonly authSrv: AuthSrv,
    private readonly snackBar: MatSnackBar,
  ) {
    this.form = new FormGroup
    ({
      email: ( this.email = new FormControl( '', [ required, email ] ) ),
      confirmEmail: ( this.confirmEmail = new FormControl( '', [ required, mustMatch('email', 'email') ] ) ),
      password: ( this.password = new FormControl( '', required ) ),
      confirmPassword: ( this.confirmPassword = new FormControl( '', [ required, mustMatch('password', 'password') ] ) ),
      agree: ( this.agree = new FormControl( false, requiredTrue ) ),
    });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.email.valueChanges.subscribe(contactEmailLabel => {
      if (this.email.value !== null) {
        if (this.email.value !== this.email.value.trim()) {
          this.form.controls['email'].setValue(contactEmailLabel.trim());
        }
      }
    });
    this.confirmEmail.valueChanges.subscribe(contactEmailLabel => {
      if (this.confirmEmail.value !== null) {
        if (this.confirmEmail.value !== this.confirmEmail.value.trim()) {
          this.form.controls['confirmEmail'].setValue(contactEmailLabel.trim());
        }
      }
    });
  }
  register()
  {
    Object.keys(this.form.controls).forEach( controlName =>
    {
      const control = this.form.get(controlName);
      control.markAsDirty();
      control.markAsTouched();
      control.updateValueAndValidity();
    });
    if ( this.form.invalid ) return;
    this.serverRegistrationErrors = [];
    this.registering = true;
    this.authSrv.register( this.email.value, this.password.value, this.agree.value ).pipe
    (
      finalize( () => { this.registering = false; } )
    )
    .subscribe
    (
      _ => this.snackBar.open( _.messages.join(' '), undefined, { duration: 3000 } ),
      e => this.serverRegistrationErrors = e instanceof AuthError ? e.data : []
    );
  }
}
