import { Component, HostBinding } from "@angular/core";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'call-form',
  templateUrl: './call-form.com.pug',
  styleUrls: ['./call-form.com.scss']
})
export class CallFormCom
{
  @HostBinding('class.x-small') private xSmallClass = false;
  @HostBinding('class.small') private smallClass = false;
  @HostBinding('class.medium') private mediumClass = false;
  @HostBinding('class.large') private largeClass = false;
  @HostBinding('class.x-large') private xLargeClass = false;
  name: string;
  phone: string;
  time: string;
  agree: boolean;
  errorMessages = [];
  constructor( readonly brkPntObs: BreakpointObserver, private http: HttpClient)
  {
    Breakpoints.XSmall = '(max-width: 304px)';
    Breakpoints.Small = '(min-width: 305px) and (max-width: 768px)';
    Breakpoints.Medium = '(min-width: 769px) and (max-width: 992px)';
    Breakpoints.Large = '(min-width: 993px) and (max-width: 1200px)';
    Breakpoints.XLarge = '(min-width: 1201px)';
    brkPntObs.observe( Breakpoints.XSmall ).subscribe( r => this.xSmallClass = r.matches );
    brkPntObs.observe( Breakpoints.Small ).subscribe( r => this.smallClass = r.matches );
    brkPntObs.observe( Breakpoints.Medium ).subscribe( r => this.mediumClass = r.matches );
    brkPntObs.observe( Breakpoints.Large ).subscribe( r => this.largeClass = r.matches );
    brkPntObs.observe( Breakpoints.XLarge ).subscribe( r => this.xLargeClass = r.matches );
  }
  success = false;
  loading = false;
  submitForm()
  {
    this.loading = true;
    this.errorMessages = [];
    this.http.post<any>( '/api/callbackrequest',
    {
      name: this.name,
      phone: '' + this.phone,
      time: this.time,
      terms: this.agree,
    })
    .pipe( finalize( () => this.loading = false ) )
    .subscribe( ({ code, messages }) =>
    {
      if ( code < 0 ) return this.errorMessages = Object.values(messages);
      this.success = true;
    } );

  }
}
