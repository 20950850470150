<header #stickyMenu [ngStyle]="{ height: disableMenuScroll ? '100px' : menuHeight+'px' }">
<div [ngClass]="{ 'navigation-container': true, active: !!showNavigation }">
<div class="logo-container">
<a class="logo" href="https://myhead.com/">
<img src="assets/images/myhead-white.svg">
</a>
</div>
<div class="initial-viewport-container">
<div class="phone-container" (click)="openModalCallback()"><img src="/myhead/customer/images/icons/navigation-icon-phone.svg"></div>
<div [ngClass]="{ 'hamburger-menu': true, active: !!showNavigation }" (click)="showNavigation = !showNavigation">
<span></span>
</div>
</div>
<div [ngClass]="{ 'navigation-elements': true, active: !!showNavigation }">
<ul class="level-0-container">
<li class="level-0-item">
<a href="https://myhead.com/#top" title="" i18n="@@menu-start">Start</a>
</li>
<li class="level-0-item">
<a title="" routerLink='/dashboard' i18n="@@menu-my-briefings">My briefings</a>
</li>
<li class="level-0-item">
<a title="" routerLink="/briefing" i18n="@@menu-find-manager">Find a manager</a>
</li>
<li class="level-0-item has-sub">
<a href="#" title="" i18n="@@menu-about-myhead">About MyHead</a>
<span class="sub-toggle"><img src="/myhead/customer/images/icons/navigation-icon-arrow.svg"></span>
<div class="sub-container active">
<ul class="level-1-container">
<li class="level-1-item">
<a href="https://myhead.com/case-studies/" title="">Case Studies</a>
</li>
<li class="level-1-item">
<a href="https://myhead.com/akzente/" title="">myHead Akzente</a>
</li>
<li class="level-1-item">
<a href="https://myhead.com/managerueberlassung-mitarbeiterueberlassung" title="" i18n="@@menu-about-transfer">Manager transfer</a>
</li>
<li class="level-1-item">
<a href="https://myhead.com/#section-tutorial" title="" i18n="@@menu-tutorial">Tutorial</a>
</li>
<li class="level-1-item">
<a href="https://myhead.com/faq" title="" i18n="@@menu-faq">FaQ</a>
</li>
</ul>
</div>
</li>
<li class="level-0-item has-sub">
<a href="#" title="" i18n="@@menu-contact">Contact</a>
<span class="sub-toggle"><img src="/myhead/customer/images/icons/navigation-icon-arrow.svg"></span>
<div class="sub-container active">
<ul class="level-1-container">
<li class="level-1-item">
<a href="#" (click)="openModalCallback()" title="" i18n="@@menu-arrange-call">Call back</a>
</li>
<li class="level-1-item">
<a href="https://myhead.com/kontaktformular" title="" i18n="@@menu-contact-form">Contact form</a>
</li>
</ul>
</div>
</li>
<li class="button-wrapper" *ngIf="isLoggedIn; else loginButtonTpl">
<a color="primary" (click)="logout()" i18n="@@button-logout">Logout</a>
</li>
<ng-template #loginButtonTpl>
<li class="button-wrapper">
<a routerLink='/auth/login' color="primary"  href="#" i18n="@@button-login"> Login </a>
</li>
</ng-template>
</ul>
</div>
</div>
</header>
<main [ngStyle]="{ 'margin-top': disableMenuScroll ? 0 : null }">
<router-outlet></router-outlet>
</main>
<footer>
<div class="footer-container">
<div class="flex-col row-inner">
<div class="col xs-12 m-3">
<div class="footer-block">
<h3>KONTAKT</h3>
<p> Atreus GmbH <br/> Landshuter Allee 8<br/> 80637 M&uuml;nchen<br/> </p>
<a class="tel" href="tel:+4989452249570" title="Phone Number"><img src="/myhead/customer/images/icons/footer-icon-phone.svg">+49 89 452 249-540</a>
<a class="mail" href="mailto:kontakt@myHead.com" title="Contact Mail"><img src="/myhead/customer/images/icons/footer-icon-mail.svg">kontakt@myHead.com</a>
</div>
<div class="footer-block">
<ul>
<li>
<a href="#" (click)="openModalCallback()" title="" i18n="@@menu-arrange-call">Call back</a>
</li>
<li>
<a href="https://myhead.com/kontaktformular" title="" i18n="@@menu-contact-form">Contact form</a>
</li>
</ul>
</div>
</div>
<div class="col xs-12 m-3">
<div class="footer-block">
<h3>MYHEAD</h3>
<ul>
<li>
<a href="#" title="" routerLink="/auth" i18n="@@button-login">Login</a>
</li>
<li>
<a href="#" title="" routerLink="/auth" i18n="@@button-register">To register</a>
</li>
<li>
<a href="#" title="" routerLink="/dashboard"  i18n="@@menu-my-briefings">My briefing</a>
</li>
</ul>
</div>
<div class="footer-block">
<ul>
<li>
<a href="https://myhead.com/agb" title="" i18n="@@link-agb">Conditions</a>
</li>
<li>
<a href="https://myhead.com/datenschutz" title="" i18n="@@link-data-protection">Data protection</a>
</li>
<li>
<a href="https://myhead.com/impressum" title="" i18n="@@link-imprint">Imprint</a>
</li>
</ul>
</div>
</div>
<div class="col xs-12 m-3">
<div class="footer-block">
<h3>&Uuml;BER MYHEAD</h3>
<ul>
<li>
<a href="https://myhead.com/managerueberlassung-mitarbeiterueberlassung" title="" i18n="@@menu-about-transfer">Manager transfer</a>
</li>
<li>
<a href="https://myhead.com/#section-testimonials" title="">Kundenstimmen</a>
</li>
<li>
<a href="https://myhead.com/#section-tutorial" title="">Tutorial</a>
</li>
<li>
<a href="https://myhead.com/case-studies/" title="">Case Studies</a>
</li>
<li>
<a href="https://myhead.com/akzente/" title="">myHead Akzente</a>
</li>
<li>
<a href="https://myhead.com/faq/" title="">FAQ</a>
</li>
<li>
<a href="https://myhead.com/sitemap/" title="">Sitemap</a>
</li>
</ul>
</div>
</div>
<div class="col xs-12 m-3">
<div class="footer-block">
<h3>KARRIERE</h3>
<ul>
<li>
<a href="https://myhead.com/myhead-experte-werden/" title="">Werde myHead Experte</a>
</li>
<li>
<a href="https://myhead.com/jobs/" title="">Jobs bei myHead</a>
</li>
</ul>
</div>
</div>
<div class="col xs-12 m-3">
<div class="footer-block">
<ul>
<li>
<a class="social-bookmarks" target="_blank" aria-label="Link to Twitter" href="https://twitter.com/atreus_gmbh?lang=de" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello" title="Twitter">
<img src="/myhead/customer/images/icons/twiter_icon.png"/>
</a>
</li>
<li>
<a  class="social-bookmarks" target="_blank" aria-label="Link to Facebook" href="https://twitter.com/atreus_gmbh?lang=de" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello" title="Facebook">
<img src="/myhead/customer/images/icons/facebook-icon.png"/>
</a>
</li>
<li>
<a class="social-bookmarks" target="_blank" aria-label="Link to Linkedin" href="https://twitter.com/atreus_gmbh?lang=de" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello" title="Linkedin">
<img src="/myhead/customer/images/icons/linkedin-icon.png"/>
</a>
</li>
<li>
<a class="social-bookmarks xing-button" target="_blank" aria-label="Link to Xing" href="https://twitter.com/atreus_gmbh?lang=de" aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello" title="Xing">
<img src="/myhead/customer/images/icons/xing-icon.png"/>
</a>
</li>
</ul>
</div>
</div>
<div class="col xs-12">
<div class="copyright">
<span>© 2020 by Atreus GmbH | All rights reserved</span>
</div>
</div>
</div>
</div>
</footer>