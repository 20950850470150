import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, zip, of } from 'rxjs';
import { map, sampleTime, tap } from 'rxjs/operators';
import { ProjectWizardSrv } from './srv';
import { extend } from '../+';
import { NgForage } from 'ngforage';

const toInternal = ( key: string ) => key.replace(/\.?([A-Z])/g, ( v: string) => `_${v.toLowerCase()}` );
const toExternal = ( key: string ) => key.replace(/-([a-z])/g, ( v ) => v.toUpperCase() );
const flatMapApiValues = ( a: any[], { key, value, children } ) => [ ...a, { key, value }, ...children ];
@Injectable()
export class ProjectStoreSrv
{
  state = {
      id: undefined,
      submitted               : false,
      title                   : undefined,
      company_name            : undefined,
      industry                : undefined,
      unit                    : undefined,
      employee_count          : undefined,
      revenue                 : undefined,
      contact_name            : undefined,
      contact_function        : undefined,
      contact_phone           : undefined,
      contact_mobile          : undefined,
      contact_email           : undefined,
      contact_email_confirm   : undefined,
      project_name            : undefined,
      description             : undefined,
      contract_type           : undefined,
      start                   : undefined,
      urgency                 : undefined,
      duration                : undefined,
      days_per_week           : undefined,
      location                : undefined,
      rate                    : undefined,
      function                : undefined,
      files                   : undefined,
      key_competences         : undefined,
      personal_requirements   : undefined,
      special_requirements    : undefined,
      languages               : undefined,
      responsibility_employees: undefined,
      responsibility_budget   : undefined,
      experience              : undefined,
      remark                  : undefined,
      selection_method        : undefined,
      created_at              : moment(),
      modified_at             : moment(),
      status                  : '',
      currentStep             : null
  };
  private initialState = extend( {}, this.state );

  private _requiredSteps = {
    1: ['company_name', 'industry', 'unit', 'revenue'],
    2: ['contact_name', 'contact_mobile', 'contact_function', 'contact_email', 'contact_email_confirm'],
    3: ['start', 'project_name', 'contract_type', 'description', 'location'],
    4: [ 'duration', 'function', 'days_per_week', 'rate'],
    5: ['key_competences'],
    6: ['personal_requirements', 'special_requirements', 'languages', ],
    7: [ 'responsibility_employees', 'responsibility_budget', 'experience'],
  };
  get steps(){
    return this._requiredSteps;
  }

  private _defaults: {
    days_per_week: 4,
    rate: 4000
  };
  get defaults(){
    return this._defaults;
  }

  private _ext_myhead_link: 'https://myhead.com/';
  get extMyheadLink(){
    return this._ext_myhead_link;
  }

  private _acommunity_link: 'https://acommunity.net/';
  get acommunityLink(){
    return this._acommunity_link;
  }

  private savedProject: any;
  initializeStore( project: any = false ): Observable<any>
  {
    if ( !project && localStorage.getItem('myhead-new') )
    {
      this.state = extend( {}, this.initialState );
      const r = extend( this.state, JSON.parse(localStorage.getItem('myhead-new'))) ;
      this.progressSubject.next(true);
      return r;
    };
    this.savedProject = project;

    return zip
    (
      this.projectWizardSrv.industryList,
      this.projectWizardSrv.revenueList,
      this.projectWizardSrv.contractTypes,
      this.projectWizardSrv.functions,
      this.projectWizardSrv.keycompetencesMapping,
      this.projectWizardSrv.languages,
      this.projectWizardSrv.personalRequirements,
      this.projectWizardSrv.specialRequirements,
    )
    .pipe
    (
      tap( ( [ industries, revenues, contractTypes, functions, keyCompetencesMap, languages, personalRequirements, specialRequirements ] ) =>
      {
        extend( this.state, project );
        const searchKey = ( sKey: any ) => ( { key } ) => key === sKey;
        this.set( 'industry', industries.reduce( flatMapApiValues, [] ).find( searchKey( project.industry ) ) );
        this.set( 'revenue', revenues.reduce( flatMapApiValues, [] ).find( searchKey( project.revenue ) ) );
        this.set( 'contract_type', contractTypes.reduce( flatMapApiValues, [] ).find( searchKey( project.contract_type ) ) );
        this.set( 'function', functions.reduce( flatMapApiValues, [] ).find( searchKey( project.function ) ) );
        // tslint:disable: no-bitwise
        this.set( 'key_competences', keyCompetencesMap[ project.function ].map( ( key: string ) => !!~project.keycompetences.indexOf(key) ) );
        this.set( 'languages', languages.map( ( { id }: { id: string } ) =>  !!~project.languages.indexOf(id) ) );
        this.set( 'personal_requirements', personalRequirements.map( ( { id } ) => !!~project.personal_requirements.indexOf(id) ) );
        this.set( 'special_requirements', specialRequirements.map( ( { id } ) => !!~project.special_requirements.indexOf(id) ) );
        this.set( 'days_per_week', +project.days_per_week );
        this.set( 'contact_email_confirm', project.contact_email );
      })
    );

  }
  deleteProject()
  {
    localStorage.removeItem('myhead-new');
    this.ngf.clear();
    extend( this.state, this.initialState );
    return true;
  }
  get( key: string ) {
    key = toInternal(key);
    if ( this.state.hasOwnProperty( key ) )
      return this.state[key];
    return null;
  }

  set( key: string, value: any )
  {
    this.state[ toInternal(key) ] = value;
    if ( !this.savedProject )
    {
      this.state.modified_at = moment();
      localStorage.setItem('myhead-new', JSON.stringify(this.state));
    }
    this.progressSubject.next(false);
  }

  computeProgress() {
    this.progressSubject.next(true);
  }

  private progressSubject = new BehaviorSubject<boolean>(undefined);
  get progress(): Observable<number>
  {
    let previousProgress = null;
    return this.progressSubject.pipe
    (
      sampleTime(1),
      map( __ => Object.keys(this._requiredSteps).reduce( ( [acc, a]: [number, boolean], k, _, requiredSteps) =>
      {
        const values = this._requiredSteps[k] as string[],
          every = +values.every( field =>
          {
            const stateField = this.state[field];
            return Array.isArray(stateField)
              ? stateField.some( f => !!f )
              // tslint:disable-next-line:no-bitwise
              : !~[ undefined, null, '' ].indexOf( stateField );
          } );
        if ( !every ) requiredSteps.splice(1);
        return [acc + every, a];
      }, [0, __] ) ),
      map( ([ progress, emit ]) =>
      {
        if ( emit ) {
          previousProgress = progress;
          return progress;
        }
        if ( previousProgress > progress ) return progress;
        return previousProgress;
      } )
    );
  }

  random = Math.random();
  constructor(
    private projectWizardSrv: ProjectWizardSrv,
    private ngf: NgForage,
  ) {
    this.initializeStore();
  }
}
