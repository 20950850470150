import { FormArray, ValidatorFn, AbstractControl } from '@angular/forms';

export class Validators
{
  static mustMatch( controlName: string, errorPrefix = '' ): ValidatorFn
  {
    return ( control: AbstractControl ): {[key: string]: any} | null =>
    {
      let sibling: AbstractControl;
      if ( !control.parent || !( sibling = control.parent.get( controlName ) ) ) return;
      const errorLabel = `${errorPrefix}MustMatch`.replace(/^(\w)/, l => l.toLowerCase() );
      return sibling.value !== control.value ? { [errorLabel]: { value: sibling.value } } : null;
    };
  }
  static atLeastOneChecked( formArray: FormArray )
  {
    return !formArray.controls.some( v => !!v.value ) ? { atLeastOneChecked: true } : null;
  }
}
export function isDate(item: any)
{
  return item && Object.prototype.toString.call(item) === '[object Date]' && !isNaN(item);
}
export function isObject(item: any)
{
  return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
}
export function extend<T, U>(target: T, source: U): T & U
{
  if ( !isObject(target) || !isObject(source) ) return target as T & U;
  Object.keys(source).forEach( key =>
  {
    if ( !source.hasOwnProperty(key) ) return;
    if ( isObject(source[key]) && !isDate(source[key]) )
    {
      if ( !target[key] ) Object.assign( target, { [key]: {} } );
      extend( target[key], source[key] );
    }
    else Object.assign( target, { [key]: source[key] });
  });
  return target as T & U;
}