import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { zip } from 'rxjs';
import * as __ from 'lodash';
import { Validators } from '../../+';
import { ABriefFormStep } from './a.com';
import { BriefStep4Type, BriefStep4Fields } from '../brief-mdl';

@Component
({
  selector: 'brief-form-step4',
  templateUrl: './step4.com.pug',
  styleUrls: ['./+.scss', './step4.com.scss'],
})
export class BriefFormStep4Com extends ABriefFormStep<BriefStep4Type> implements OnInit
{
  selectableKeyCompetences: any[];
  form: FormGroup;
  fieldKeys = BriefStep4Fields;
  ngOnInit()
  {
    this.form = new FormGroup
    ({
      [this.fieldKeys.keyCompetences]: (this.fields[this.fieldKeys.keyCompetences] = new FormArray([], Validators.atLeastOneChecked))
    });

  }

  protected fillDataCallback( data: any )
  {
    zip
    (
      this.apiSrv.keycompetencesMapping,
      this.apiSrv.keycompetences,
    )
    .pipe
    (
      tap(([mapping, keyCompetences]) =>
      {
        this.selectableKeyCompetences = (data.function ? mapping[data.function.key] : [])
          .map( (key: string) => ({ id: key, value: keyCompetences[key] }) )
          .sort( (a: {value: string}, b: {value: string}) => a.value < b.value ? -1 : 1 );
        const keyCompetencesField = this.fields.keyCompetences as FormArray;
        if ( keyCompetencesField.controls.length < this.selectableKeyCompetences.length )
          Array(this.selectableKeyCompetences.length - keyCompetencesField.controls.length).fill(undefined)
            .forEach( (_, i) => keyCompetencesField.push(new FormControl( (data.keyCompetences && data.keyCompetences[i]) || false )) );
      })
    ).subscribe();
  }
}
