import { Injectable } from '@angular/core';
import {
  BriefStateMdl,
  BriefStep1RequiredFields,
  BriefStep2RequiredFields,
  BriefStep3RequiredFields,
  BriefStep4RequiredFields,
  BriefStep5RequiredFields,
  BriefStep6RequiredFields,
  BriefStatuses,
  BriefStep1Fields,
} from './brief-mdl';
import { NgForage } from 'ngforage';
import { zip, from, of } from 'rxjs';
import { v4 as uuid } from 'uuid';

const fieldValid = ( state: BriefStateMdl ) => ( field: string ) => state.dataStatus[field];
@Injectable()
export class BriefStateFty
{
  constructor( private readonly ngf: NgForage, ) {}
  statusValues = Object.values(BriefStatuses);
  steps =
  [
    BriefStep1RequiredFields,
    BriefStep2RequiredFields,
    BriefStep3RequiredFields,
    BriefStep4RequiredFields,
    BriefStep5RequiredFields,
    BriefStep6RequiredFields,
  ];
  create( brief: { data?: any, [key: string]: any } ): BriefStateMdl
  {
    const briefState = new BriefStateMdl;
    briefState.createdAt = new Date(brief.created_at || null);
    briefState.modifiedAt = new Date(brief.modified_at || null);
    const searchStatus = this.statusValues.find( status => status === brief.status );
    briefState.status = searchStatus ? searchStatus : ( !brief.status ? BriefStatuses.DRAFT : BriefStatuses.INITIATED );

    if ( brief.id ) briefState.id = brief.id;
    if ( brief.project_id ) briefState.id = brief.project_id;

    briefState.uuid = brief.uuid || uuid();
    briefState.data = brief.data || brief;
    briefState.dataStatus = !brief.dataStatus
      // legacy data
      ? Object.entries( brief.data || brief ).map( ([k, v]) =>
      {
        if ( Array.isArray(v) ) return [k, v.some( _ => !!_ )];
        return [k, !!v];
      }).reduce( (a, [k, v]: [string, boolean]) => (a[k] = v, a), {})
      : brief.dataStatus;

    Object.defineProperty( briefState, 'steps', { value: this.steps, writable: false } );
    Object.defineProperty( briefState, 'progress', { get()
    {
      briefState.submittable =
        ( Object.keys(BriefStep1Fields).every( fieldValid(briefState) ) && !!briefState.data.files.length )
        || Object.keys(BriefStep1RequiredFields).every( fieldValid(briefState) ) && Object.keys(BriefStep2RequiredFields).every( fieldValid(briefState) );
      return Object.values(this.steps).reduce<number>( ( acc, k, i, stepsArray) =>
      {
        const every = +Object.keys(k).every( fieldValid(briefState) );
        if ( !every ) stepsArray.splice(1);
        return acc + every;
      }, 0 ) / this.steps.length;
    } });

    return briefState;
  }
}
