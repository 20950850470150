<div class="col">
<div class="content-container"><label class="xs" i18n="@@label-step5">Step 5</label><h1 i18n="@@title-personal-req"> Persönliche Anforderungen </h1>
<p i18n="@@description-project-step5"> Zuletzt benötigen wir die persönlichen und besonderen Anforderungen an die Kandidaten. </p>
</div>
</div>
<form class="col xs-12" [formGroup]="form">
<div class="form-container">
<div class="form-group flex-col row-inner">
<div class="col xs-12 m-4 checkboxes">
<h2 i18n="@@label-personal-req"> Anforderungen persönlich </h2><ng-container formArrayName="personalRequirements" *ngIf="personalRequirementsList; else loadingTpl"><ng-container *ngFor=" let pReq of personalRequirementsList; index as i "><mat-checkbox [formControlName]="i">{{pReq.value}}</mat-checkbox></ng-container></ng-container><mat-error class="field-error" *ngIf="fields.personalRequirements.dirty" [control]="fields.personalRequirements"></mat-error></div>
<div class="col xs-12 m-4 checkboxes">
<h2 i18n="@@label-special-exp"> Erfahrung in Sondersituationen </h2><ng-container formArrayName="specialSituations" *ngIf="specialRequirementsList; else loadingTpl"><ng-container *ngFor=" let sReq of specialRequirementsList; index as i "><mat-checkbox [formControlName]="i">{{sReq.value}}</mat-checkbox></ng-container></ng-container><mat-error class="field-error" *ngIf="fields.specialSituations.dirty" [control]="fields.specialSituations"></mat-error></div>
<div class="col xs-12 m-4 checkboxes">
<h2 i18n="@@label-lang-fluent"> Sprachen (fließend) </h2><ng-container formArrayName="languages" *ngIf="languagesList; else loadingTpl"><ng-container *ngFor=" let lang of languagesList; index as i "><mat-checkbox [formControlName]="i">{{lang.value}}</mat-checkbox></ng-container></ng-container><mat-error class="field-error" *ngIf="fields.languages.dirty" [control]="fields.languages"></mat-error></div>
</div>
</div>
</form><ng-template #loadingTpl><ng-container i18n="@@loading">Loading...</ng-container></ng-template><div class="col"><div class="form-container"><div class="button-group"><div *ngIf="!submitted; else onlyCloseButtonTpl"><button mat-button type="button" (click)="cancel()" i18n="@@button-cancel"><mat-icon svgIcon="fa:trash-alt"></mat-icon> Delete……</button><button mat-button type="button" (click)="save()" i18n="@@button-save"><mat-icon svgIcon="fa:save"></mat-icon> Save………</button></div><ng-template #onlyCloseButtonTpl><button mat-button type="button" (click)="cancel()" i18n="@@button-close"><mat-icon>close</mat-icon> Close………</button></ng-template><div><button mat-button type="button" color="primary" (click)="prev()" i18n="@@button-previous-step"><mat-icon>keyboard_arrow_left</mat-icon> Previous… Step………</button><button mat-button *ngIf="!submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-step-optional-icon">Next… (Optional) <mat-icon>keyboard_arrow_right</mat-icon></button><button mat-button *ngIf="submitted" type="button" color="primary" (click)="next()" i18n="@@button-next-icon">Next…  <mat-icon>keyboard_arrow_right</mat-icon></button></div></div></div><div class="full" *ngIf="!submitted"><p class="error" *ngIf="submitError">Server error!</p><button class="submit" mat-flat-button type="button" color="primary" (click)="submit()" [disabled]="submitting"><span i18n="@@button-submit">Submit…</span><div class="loading"><mat-spinner diameter="30" strokeWidth="3"></mat-spinner></div></button></div></div><div class="col">
<div class="content-container">
<small i18n="@@text-project-step5-outro"> Mit "Zwischenspeichern & Beenden" unterbrechen Sie das Projektbriefing und speichern die Daten für die spätere Weiterbearbeitung. Auf Ihrer passwortgeschützten myHead-Seite können Sie jedes Projektbriefing wieder aufrufen und fertigstellen - egal ob vom Handy, iPad oder am Rechner. Mit "Nächster Schritt" gelangen Sie zur folgenden Seite des Briefings. </small>
</div>
</div>